import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface ConfirmationModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title?: string;
  description: string;
  onConfirm: () => void;
  onCancel: () => void;
  skipConfirmationKey?: string;
  dontAskAgainLabel?: string;
}

export default function ConfirmationModal({
  open,
  onOpenChange,
  title = "Är du säker?",
  description,
  onConfirm,
  onCancel,
  skipConfirmationKey,
  dontAskAgainLabel = "Fråga inte igen för denna sorts uppdatering",
}: ConfirmationModalProps) {
  const [dontAskAgain, setDontAskAgain] = useState(false);

  const handleConfirm = () => {
    // Save the "don't ask again" preference if checked and a key is provided
    if (dontAskAgain && skipConfirmationKey) {
      localStorage.setItem(skipConfirmationKey, "true");
    }

    // Execute the confirmation callback
    onConfirm();

    // Reset state
    setDontAskAgain(false);
  };

  const handleCancel = () => {
    onCancel();
    setDontAskAgain(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>

        {skipConfirmationKey && (
          <div className="flex items-center space-x-2 py-4">
            <input
              type="checkbox"
              id={`dontAskAgain-${skipConfirmationKey}`}
              checked={dontAskAgain}
              onChange={(e) => setDontAskAgain(e.target.checked)}
              className="h-4 w-4"
            />
            <label
              htmlFor={`dontAskAgain-${skipConfirmationKey}`}
              className="text-sm font-medium"
            >
              {dontAskAgainLabel}
            </label>
          </div>
        )}

        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button type="button" variant="outline" onClick={handleCancel}>
              Avbryt
            </Button>
          </DialogClose>
          <Button variant="ef" type="button" onClick={handleConfirm}>
            Bekräfta
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
