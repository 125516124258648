import {ToggleGroup, ToggleGroupItem} from "@/components/ui/toggle-group";
import type {ComponentProps} from "react";

export default function WeekDaysToggleGroup(props: ComponentProps<typeof ToggleGroup>) {
  return (
    <>
      <ToggleGroup variant="outline" {...{...props}}>
        <ToggleGroupItem value="1">M</ToggleGroupItem>
        <ToggleGroupItem value="2">T</ToggleGroupItem>
        <ToggleGroupItem value="3">O</ToggleGroupItem>
        <ToggleGroupItem value="4">T</ToggleGroupItem>
        <ToggleGroupItem value="5">F</ToggleGroupItem>
        <ToggleGroupItem value="6">L</ToggleGroupItem>
        <ToggleGroupItem value="0">S</ToggleGroupItem>
      </ToggleGroup>
    </>
  );
}