import Input from "@/components/ui/Input";
import { useForm } from "react-hook-form";
import type { Article } from "../../../../hooks/api/types";
import FilterControls from "./FilterControls";
import ActionControls from "./ActionControls";

interface PlannerTableControlsProps {
  articles: Article[];
  filteredArticles: Article[];
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  setActiveSearchPhrase: (phrase: string) => void;
  selectedResponsiblePeople: { value: number; label: string }[];
  setSelectedResponsiblePeople: (
    people: { value: number; label: string }[],
  ) => void;
  selectedStatuses: { value: string; label: string }[];
  setSelectedStatuses: (statuses: { value: string; label: string }[]) => void;
  selectedFocusAreas: { value: number; label: string }[];
  setSelectedFocusAreas: (areas: { value: number; label: string }[]) => void;
  selectedContentTypes: { value: string; label: string }[];
  setSelectedContentTypes: (types: { value: string; label: string }[]) => void;
  people: { id: number; name: string }[];
  statuses: string[];
  focusAreas: { id: number; name: string }[];
  contentTypes: { value: string; label: string }[];
}

export default function PlannerTableControls({
  articles,
  filteredArticles,
  selectedIds,
  setSelectedIds,
  setActiveSearchPhrase,
  selectedResponsiblePeople,
  setSelectedResponsiblePeople,
  selectedStatuses,
  setSelectedStatuses,
  selectedFocusAreas,
  setSelectedFocusAreas,
  selectedContentTypes,
  setSelectedContentTypes,
  people,
  statuses,
  focusAreas,
  contentTypes,
}: PlannerTableControlsProps) {
  const { register, handleSubmit } = useForm();

  return (
    <div className="bg-slate-300/90 px-4 py-3 2xl:py-4 h-16 2xl:h-24 flex gap-4 2xl:gap-16 items-end sticky top-0 z-10 backdrop-blur supports-[backdrop-filter]:bg-slate-300/60">
      <div>
        <span className="block text-lg font-bold hidden 2xl:block">Frisök</span>
        <form
          onSubmit={handleSubmit((data) => setActiveSearchPhrase(data.phrase))}
          className="flex items-center gap-2"
        >
          <span className="hidden 2xl:inline">Sök</span>
          <Input
            placeholder="Sök..."
            {...register("phrase")}
            autoComplete="off"
          />
        </form>
      </div>

      {/* Filter Controls */}
      <FilterControls
        people={people}
        statuses={statuses}
        focusAreas={focusAreas}
        contentTypes={contentTypes}
        selectedResponsiblePeople={selectedResponsiblePeople}
        setSelectedResponsiblePeople={setSelectedResponsiblePeople}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        selectedFocusAreas={selectedFocusAreas}
        setSelectedFocusAreas={setSelectedFocusAreas}
        selectedContentTypes={selectedContentTypes}
        setSelectedContentTypes={setSelectedContentTypes}
        filteredArticlesCount={filteredArticles.length}
      />

      {/* Action Controls */}
      <ActionControls
        articles={articles}
        selectedIds={selectedIds}
        people={people}
      />
    </div>
  );
}
