import type { Draft } from "immer";
import type { GetState, SetState, StateCreator, StoreApi } from "zustand";
import create from "zustand";
import produce from "immer";

type State = {
  mainMenu: {
    isVisible: boolean;
    toggleIsVisible: () => void;
    setIsVisible: (isOpen: boolean) => void;
  };
};

const immer =
  <
    T extends State,
    CustomSetState extends SetState<T>,
    CustomGetState extends GetState<T>,
    CustomStoreApi extends StoreApi<T>,
  >(
    config: StateCreator<
      T,
      (partial: ((draft: Draft<T>) => void) | T, replace?: boolean) => void,
      CustomGetState,
      CustomStoreApi
    >,
  ): StateCreator<T, CustomSetState, CustomGetState, CustomStoreApi> =>
  (set, get, api) =>
    config(
      (partial, replace) => {
        const nextState =
          typeof partial === "function"
            ? produce(partial as (state: Draft<T>) => T)
            : (partial as T);
        return set(nextState, replace);
      },
      get,
      api,
    );

const useStore = create<State>(
  immer((set, get) => ({
    mainMenu: {
      isVisible: false, // default visibility for the menu
      toggleIsVisible: () => {
        set((state) => {
          state.mainMenu.isVisible = !state.mainMenu.isVisible;
        });
      },
      setIsVisible: (bool) => {
        set((state) => {
          state.mainMenu.isVisible = bool;
        });
      },
    },
  })),
);

export default useStore;
