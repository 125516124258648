import { Button } from "@/components/ui/button";
import React from "react";

type Props = {
  selectedDays: number[];
  onDayClick: (day: number) => void;
  disabled: boolean;
};

export default function MonthDaysButtonGroup({
  selectedDays,
  onDayClick,
  disabled = false,
}: Props) {
  return (
    <>
      <div className="grid grid-cols-7 gap-0.5">
        {"1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31"
          .split(" ")
          .map((nr) => Number(nr))
          .map((nr) => (
            <Button
              onClick={() => onDayClick(nr)}
              type="button"
              disabled={disabled}
              variant={selectedDays.includes(nr) ? "secondary" : "ghost"}
              size="sm"
              key={nr}
            >
              {nr}
            </Button>
          ))}
      </div>
    </>
  );
}
