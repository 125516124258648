import MonthDaysButtonGroup from "@/components/forms/RecurrenceForm/FormFields/MonthDaysButtonGroup";
import MonthlyOccurrencesSelect from "@/components/forms/RecurrenceForm/FormFields/MonthlyOccurrencesSelect";
import MonthlyWeekdaySelect, {
  type WeekdayNumber,
} from "@/components/forms/RecurrenceForm/FormFields/MonthlyWeekdaySelect";
import RepeatFrequencySelect, {
  type RepeatFrequency,
} from "@/components/forms/RecurrenceForm/FormFields/RepeatFrequencySelect";
import StartDatePopover from "@/components/forms/RecurrenceForm/FormFields/StartDatePopover";
import WeekDaysToggleGroup from "@/components/forms/RecurrenceForm/FormFields/WeekDaysToggleGroup";
import type {
  MonthlyOccurrence,
  RecurrenceConfig,
} from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import RecurrenceDescription from "@/components/RecurrenceDescription";
import { Button } from "@/components/ui/button";
import { DialogFooter } from "@/components/ui/dialog";
import Input from "@/components/ui/Input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import type { Article } from "@/hooks/api/types";
import { useArticlesMutation } from "@/hooks/api/useArticlesMutation";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

type Props = {
  disabled: boolean;
  recurrenceConfig: RecurrenceConfig;
  onSave: (data: RecurrenceConfig) => void;
  savingStatus: "error" | "idle" | "loading" | "success";
};

export const recurrenceConfigDefaults: RecurrenceConfig = {
  repeatFrequency: "never",
  dailyInterval: 1,
  weeklyInterval: 1,
  monthlyInterval: 1,
  monthlyRepeatMode: "byMonthDays",
  monthlyOccurrence: "first",
  monthlyWeekday: "day",
  weeklyDays: [],
  desiredTime: "12",
  startDate: new Date(),
};

export default function RecurrenceForm({
  disabled,
  recurrenceConfig,
  onSave,
  savingStatus,
}: Props) {
  const [repeatFrequency, setRepetitionFrequency] = useState<RepeatFrequency>(
    recurrenceConfig.repeatFrequency ?? "never",
  );
  const [selectedMonthDays, setSelectedMonthDays] = useState<number[]>(
    recurrenceConfig.selectedMonthDays ?? [],
  );
  const { register, handleSubmit, watch, setValue } = useForm<RecurrenceConfig>(
    {
      defaultValues: recurrenceConfig,
    },
  );
  const [
    dailyInterval,
    weeklyInterval,
    monthlyInterval,
    monthlyRepeatMode,
    monthlyOccurrence,
    monthlyWeekday,
    weeklyDays,
    startDate,
    desiredTime,
  ] = watch([
    "dailyInterval",
    "weeklyInterval",
    "monthlyInterval",
    "monthlyRepeatMode",
    "monthlyOccurrence",
    "monthlyWeekday",
    "weeklyDays",
    "startDate",
    "desiredTime",
  ]);

  const repConfig = {
    repeatFrequency,
    monthlyInterval,
    monthlyRepeatMode,
    selectedMonthDays,
    dailyInterval,
    weeklyInterval,
    weeklyDays,
    monthlyOccurrence,
    monthlyWeekday,
    startDate,
    desiredTime,
  };

  return (
    <form
      className="flex flex-col gap-2"
      onSubmit={handleSubmit(() => onSave(repConfig))}
    >
      <StartDatePopover
        startDate={startDate}
        onChange={(d) => setValue("startDate", d)}
      />
      <RepeatFrequencySelect
        value={repeatFrequency}
        setValue={setRepetitionFrequency}
        disabled={disabled}
      />
      {!disabled && (
        <>
          {repeatFrequency === "daily" && (
            <div className="flex items-center gap-2">
              med intervall på
              <Input
                {...register("dailyInterval", { valueAsNumber: true })}
                type="number"
                min="1"
                placeholder="1"
                className="w-16 text-right"
              />
              {dailyInterval! > 1 ? "dagar" : "dag"}
            </div>
          )}
          {repeatFrequency === "weekly" && (
            <>
              <div className="flex items-center gap-2">
                med intervall på
                <Input
                  {...register("weeklyInterval", { valueAsNumber: true })}
                  type="number"
                  min="1"
                  placeholder="1"
                  className="w-16 text-right"
                />
                {weeklyInterval && weeklyInterval > 1 ? "veckor" : "vecka"}
              </div>
              <div className="flex gap-1 items-center">
                dagar:
                <WeekDaysToggleGroup
                  {...register("weeklyDays", {
                    setValueAs: (numbers) => numbers.map(Number),
                  })}
                  type="multiple"
                  onValueChange={(days: string[]) =>
                    setValue("weeklyDays", days.map(Number) as WeekdayNumber[])
                  }
                  defaultValue={(weeklyDays || []).map((d) => d.toString())}
                />
              </div>
            </>
          )}
          {repeatFrequency === "monthly" && (
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                med intervall på
                <Input
                  {...register("monthlyInterval", { valueAsNumber: true })}
                  type="number"
                  min="1"
                  placeholder="1"
                  className="w-16 text-right"
                />
                {monthlyInterval! > 1 ? "månader" : "månad"}
              </div>
              <div>
                <RadioGroup
                  defaultValue={monthlyRepeatMode}
                  onValueChange={(v) =>
                    setValue(
                      "monthlyRepeatMode",
                      v as "byMonthDays" | "byWeekdayOccurrence",
                    )
                  }
                  {...register("monthlyRepeatMode")}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value="byMonthDays"
                      id="monthlyRepeatMode1"
                    />
                    <Label htmlFor="monthlyRepeatMode1">Varje</Label>
                  </div>
                  <MonthDaysButtonGroup
                    selectedDays={selectedMonthDays}
                    onDayClick={(nr) => {
                      setSelectedMonthDays((prev) =>
                        prev.includes(nr)
                          ? prev.filter((d) => d !== nr)
                          : [...prev, nr],
                      );
                    }}
                    disabled={monthlyRepeatMode !== "byMonthDays"}
                  />
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value="byWeekdayOccurrence"
                      id="monthlyRepeatMode2"
                    />
                    <Label htmlFor="monthlyRepeatMode2">Den</Label>
                  </div>
                  <div className="flex gap-2 items-center">
                    <MonthlyOccurrencesSelect
                      {...register("monthlyOccurrence")}
                      onValueChange={(v) =>
                        setValue("monthlyOccurrence", v as MonthlyOccurrence)
                      }
                      defaultValue={monthlyOccurrence}
                      disabled={monthlyRepeatMode !== "byWeekdayOccurrence"}
                    />
                    <MonthlyWeekdaySelect
                      {...register("monthlyWeekday")}
                      onValueChange={(v) =>
                        setValue(
                          "monthlyWeekday",
                          v === "day" ? v : (Number(v) as WeekdayNumber),
                        )
                      }
                      defaultValue={monthlyWeekday?.toString()}
                      disabled={monthlyRepeatMode !== "byWeekdayOccurrence"}
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>
          )}
          <div>
            <Select
              {...register("desiredTime")}
              onValueChange={(time) => setValue("desiredTime", time)}
              defaultValue={desiredTime}
            >
              <SelectTrigger>
                <SelectValue placeholder="Välj tid" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Tid</SelectLabel>
                  {"01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23"
                    .split(" ")
                    .map((hour) => (
                      <SelectItem key={hour} value={hour}>
                        {hour}:00
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </>
      )}
      <div className="my-4">
        <RecurrenceDescription
          repConfig={repConfig}
          startDate={startDate}
          nextDates={5}
        />
      </div>
      <DialogFooter>
        <Button variant="ef" disabled={savingStatus === "loading"}>
          {savingStatus === "loading" ? "Sparar..." : "Spara"}
        </Button>
      </DialogFooter>
    </form>
  );
}
