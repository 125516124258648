import AddDatePopover from "@/components/forms/RecurrenceForm/FormFields/AddDatePopover";
import {
  getNextArticleDates,
  setTimeFromConfig,
} from "@/components/forms/RecurrenceForm/helpers/articleDates";
import { PencilIcon } from "lucide-react";
import { describeRecurrenceOptions } from "@/components/forms/RecurrenceForm/helpers/generateNextDays";
import RecurrenceForm, {
  recurrenceConfigDefaults,
} from "@/components/forms/RecurrenceForm/RecurrenceForm";
import type { RecurrenceConfig } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import FutureDates from "@/components/forms/UpdatePlannerForm/FutureDates";
import MacrobondTable from "@/components/forms/UpdatePlannerForm/MacrobondTable";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";
import TextArea from "@/components/ui/TextArea";
import type { Article } from "@/hooks/api/types";
import useArticleDetailsQuery from "@/hooks/api/useArticleDetailsQuery";
import { useArticlesMutation } from "@/hooks/api/useArticlesMutation";
import { mutationToPromise } from "@/lib/utils";
import { isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

export default function PlannerItem({
  article,
  onClose,
}: {
  article: Article;
  onClose?: () => void;
}) {
  const [recurrenceConfig, setRecurrenceConfig] = useState<RecurrenceConfig>(
    Object.assign(
      {},
      recurrenceConfigDefaults,
      article.reviewOccurrenceConfiguration,
    ),
  );
  const [recurrenceExceptions, setRecurrenceExceptions] = useState<
    Record<string, boolean | string>
  >(article.reviewOccurrenceExceptions ?? {});
  const [newDatePopoverOpen, setNewDatePopoverOpen] = useState(false);
  const [showRecurrenceDialog, setShowRecurrenceDialog] = useState(false);
  const [showReviewDoneDialog, setShowReviewDoneDialog] = useState(false);
  const reviewCommentRef = useRef<HTMLTextAreaElement>(null);

  const articlesMutation = useArticlesMutation();
  const articleDetailsQuery = useArticleDetailsQuery(article.id);

  useEffect(() => {
    if (!articleDetailsQuery.isSuccess) {
      return;
    }
    const details = articleDetailsQuery.data;
    const originalExceptions = details.reviewOccurrenceExceptions ?? {};
    const originalConfig = details.reviewOccurrenceConfiguration ?? {};
    if (
      isEqual(recurrenceExceptions, originalExceptions) &&
      (isEqual(recurrenceConfig, originalConfig) ||
        isEqual(recurrenceConfig, recurrenceConfigDefaults))
    ) {
      // nothing changed
      return;
    }

    const data = {
      articleId: article.id,
      reviewOccurrenceExceptions: recurrenceExceptions ?? originalExceptions,
      reviewOccurrenceConfiguration: recurrenceConfig ?? originalConfig,
    };

    const mutationPromise = mutationToPromise(articlesMutation.mutate, data);

    toast
      .promise(mutationPromise, {
        loading: "Sparar...",
        success: "Sparat!",
        error: "Error",
      })
      .then(() => {
        setShowRecurrenceDialog(false);
      });
  }, [recurrenceExceptions, recurrenceConfig]);

  useEffect(() => {
    if (articleDetailsQuery.status === "success") {
      if (articleDetailsQuery.data.reviewOccurrenceConfiguration) {
        setRecurrenceConfig(
          articleDetailsQuery.data.reviewOccurrenceConfiguration,
        );
      }
      setRecurrenceExceptions(
        articleDetailsQuery.data.reviewOccurrenceExceptions,
      );
    }
  }, [articleDetailsQuery.isSuccess]);

  const nextDates = getNextArticleDates({
    article: {
      ...article,
      reviewOccurrenceExceptions: recurrenceExceptions,
      reviewOccurrenceConfiguration: recurrenceConfig,
    },
    count: 5,
    usePostponedForSorting: true,
  });

  function addDate(date: Date) {
    date = setTimeFromConfig(date, article.reviewOccurrenceConfiguration);
    setRecurrenceExceptions({
      ...recurrenceExceptions,
      [date.toISOString()]: true,
    });
  }

  function restoreDate(date: Date) {
    setRecurrenceExceptions((old) => ({
      ...old,
      [date.toISOString()]: true,
    }));
  }

  function removeDate(date: Date) {
    setRecurrenceExceptions((old) => ({ ...old, [date.toISOString()]: false }));
  }

  function changeDate(oldDate: Date, newDate: Date) {
    setRecurrenceExceptions((old) => ({
      ...old,
      [oldDate.toISOString()]: newDate.toISOString(),
    }));
  }

  function markAsReviewedAndUpdated() {
    const reviewComment = reviewCommentRef.current?.value || "";

    const promise = mutationToPromise(articlesMutation.mutate, {
      articleId: article.id,
      reviewOccurrenceConfiguration: recurrenceConfig,
      reviewOccurrenceExceptions: recurrenceExceptions,
      reviewDoneNow: true,
      reviewComment: reviewComment,
    });

    toast
      .promise(promise, {
        loading: "Uppdaterar...",
        success: "Klart!",
        error: "Error",
      })
      .then(() => {
        // Close the review done dialog
        setShowReviewDoneDialog(false);

        // Close the parent dialog if onClose prop exists
        if (onClose) {
          onClose();
        }
      });
  }

  return (
    <div>
      <div className="flex items-center gap-2">
        <div>
          <h2>{article.title}</h2>
        </div>
        <div>
          <a href={article.cueUrl} target="_blank" rel="noreferrer">
            <span className="text-xs text-sn-blue text-nowrap">
              Öppna i CUE
            </span>
          </a>
        </div>
      </div>
      <div className="text-sm text-gray-600 mb-4">
        ID: {article.id} Senast granskad och uppdaterad:{" "}
        {article.latestArticleReviewDate} av {article.pageManager?.name}
      </div>
      <div className="">
        <h4 className="font-bold mb-2">
          Kommande datum för granskning och uppdatering
        </h4>
        {articleDetailsQuery.isLoading ? (
          <div className="flex flex-col gap-0.5">
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-10" />
            <Skeleton className="w-full h-5" />
          </div>
        ) : (
          <div className="scroll-box">
            <ScrollArea className="w-full">
              <div className="max-h-[12.7rem]">
                <FutureDates
                  nextDates={nextDates}
                  onDateChange={changeDate}
                  onDateRemove={removeDate}
                  onDateRestore={restoreDate}
                />
              </div>
            </ScrollArea>
          </div>
        )}
        <div className="flex justify-between text-right mt-1 mr-4">
          <Dialog
            open={showReviewDoneDialog}
            onOpenChange={setShowReviewDoneDialog}
          >
            <DialogTrigger asChild>
              <Button variant="ef">Granskning och uppdatering klar</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Granskning och uppdatering</DialogTitle>
              </DialogHeader>
              <TextArea
                className="rounded-none"
                placeholder="Kommentär (frivillig)"
                ref={reviewCommentRef}
              />
              <div className="text-right mt-2 flex gap-4 justify-end">
                <DialogClose asChild>
                  <Button variant="outline">Avbryt</Button>
                </DialogClose>
                <Button
                  variant="ef"
                  disabled={articlesMutation.isLoading}
                  onClick={() => markAsReviewedAndUpdated()}
                >
                  Ok
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <AddDatePopover
            open={newDatePopoverOpen}
            onOpenChange={setNewDatePopoverOpen}
            buttonDisabled={articleDetailsQuery.isLoading}
            disabledDates={{ before: new Date() }}
            onSelect={(d) => {
              addDate(d);
              setNewDatePopoverOpen(false);
            }}
          />
        </div>
      </div>
      <div className="my-4">
        <h4 className="mb-2 font-bold">Granskningsintervall</h4>
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            {recurrenceConfig && (
              <span>{describeRecurrenceOptions(recurrenceConfig)}</span>
            )}
          </div>
          <Dialog
            open={showRecurrenceDialog}
            onOpenChange={setShowRecurrenceDialog}
          >
            <DialogTrigger asChild>
              <Button
                variant="outline"
                onClick={() => setShowRecurrenceDialog(true)}
              >
                <PencilIcon size={16} />
                ändra
              </Button>
            </DialogTrigger>
            <DialogContent>
              <h4 className="mb-2 font-bold">Granskningsintervall</h4>
              <RecurrenceForm
                disabled={false}
                recurrenceConfig={recurrenceConfig}
                onSave={(config) => {
                  setRecurrenceConfig(config);
                }}
                savingStatus={articlesMutation.status}
              />
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="mt-6">
        <h4 className="mb-2 font-bold">
          Diagram och tabeller på denna faktasida
        </h4>
        <MacrobondTable
          isLoading={articleDetailsQuery.isLoading}
          infographics={articleDetailsQuery.data?.infographics ?? []}
        />
      </div>
    </div>
  );
}
