import Icon from "./Icon";
import React from "react";

interface TablePaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  currentPage?: number;
}

const TablePagination = (props: TablePaginationProps) => {
  
  return (
    <div className="pagination flex justify-center items-center my-4 gap-1">
      <button className={`flex p-2 rounded shadow ${props.canPreviousPage ? 'bg-white hover:bg-slate-200' : 'bg-gray-300'}`} onClick={() => props.gotoPage(0)} disabled={!props.canPreviousPage}>
        <Icon icon="chevronLeft" color={props.canPreviousPage ? 'black' : '#aaa'} />
        <Icon icon="chevronLeft" color={props.canPreviousPage ? 'black' : '#aaa'} />
      </button>
      <button className={`flex p-2 rounded shadow ${props.canPreviousPage ? 'bg-white hover:bg-slate-200' : 'bg-gray-300'}`} onClick={() => props.previousPage()} disabled={!props.canPreviousPage}>
        <Icon icon="chevronLeft" color={props.canPreviousPage ? 'black' : '#aaa'} />
      </button>
      <span className="mx-2">
        Sida {(props.currentPage !== undefined ? props.currentPage + 1 : 1)} av {props.pageCount}
      </span>
      <button className={`flex p-2 rounded shadow ${props.canNextPage ? 'bg-white hover:bg-slate-200' : 'bg-gray-300'}`} onClick={() => props.nextPage()} disabled={!props.canNextPage}>
        <Icon icon="chevronRight" color={props.canNextPage ? 'black' : '#aaa'} />
      </button>
      <button className={`flex p-2 rounded shadow ${props.canNextPage ? 'bg-white hover:bg-slate-200' : 'bg-gray-300'}`} onClick={() => props.gotoPage(props.pageCount - 1)} disabled={!props.canNextPage}>
        <Icon icon="chevronRight" color={props.canNextPage ? 'black' : '#aaa'} />
        <Icon icon="chevronRight" color={props.canNextPage ? 'black' : '#aaa'} />
      </button>
    </div>
  )
}

export default TablePagination;
