import { useState } from "react";
import { Button } from "@/components/ui/button";
import { RefreshCwIcon, PlusIcon, Undo2Icon } from "lucide-react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { sv } from "date-fns/locale";
import RecurrenceForm, {
  recurrenceConfigDefaults,
} from "@/components/forms/RecurrenceForm/RecurrenceForm";
import SelectPeople from "./SelectControls/SelectPeople";
import type { RecurrenceConfig } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import useConfirmation from "../hooks/useConfirmation";

// Define the type for the return value of useConfirmation hook
type ConfirmationHook<T> = ReturnType<typeof useConfirmation<T>>;

interface ActionSheetProps {
  selectedIds: number[];
  articlesCount: number;
  people: { id: number; name: string }[];
  changingResponsible: boolean;
  selectedResponsible: { value: number; label: string } | null;
  setSelectedResponsible: (
    value: { value: number; label: string } | null,
  ) => void;
  handleChangeResponsible: (pageManagerId: number) => void;
  handleAddDate: (date: Date) => void;
  handleSetInterval: (config: RecurrenceConfig) => void;
  responsibleConfirmation: ConfirmationHook<number>;
  dateConfirmation: ConfirmationHook<Date>;
  intervalConfirmation: ConfirmationHook<RecurrenceConfig>;
  resetDontAskAgainSetting: () => void;
  resetDontAskAgainDateSetting: () => void;
  resetDontAskAgainIntervalSetting: () => void;
  savingStatus: "idle" | "loading" | "success" | "error";
}

export default function ActionSheet({
  selectedIds,
  articlesCount,
  people,
  changingResponsible,
  selectedResponsible,
  setSelectedResponsible,
  handleChangeResponsible,
  handleAddDate,
  handleSetInterval,
  responsibleConfirmation,
  dateConfirmation,
  intervalConfirmation,
  resetDontAskAgainSetting,
  resetDontAskAgainDateSetting,
  resetDontAskAgainIntervalSetting,
  savingStatus,
}: ActionSheetProps) {
  const [showMobileCalendar, setShowMobileCalendar] = useState(false);
  const [showRecurrenceDialog, setShowRecurrenceDialog] = useState(false);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" className="flex items-center gap-2">
          <RefreshCwIcon className="h-4 w-4 mr-1" />
          Åtgärder ({selectedIds.length})
        </Button>
      </SheetTrigger>
      <SheetContent
        side="right"
        className="w-[350px] sm:w-[450px] lg:w-[500px] flex flex-col"
      >
        <SheetHeader>
          <SheetTitle>Åtgärder</SheetTitle>
        </SheetHeader>
        <div className="flex flex-col gap-4 py-4 flex-grow overflow-y-auto">
          <div className="text-sm">
            <span>
              {selectedIds.length} av {articlesCount} artiklar markerade
            </span>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Ny ansvarig</label>
            <SelectPeople
              people={people}
              value={selectedResponsible}
              onChange={(option) => {
                setSelectedResponsible(option);
                if (option?.value) {
                  // Close the sheet before showing the confirmation dialog
                  document.body.click(); // This will close the sheet
                  // Wait a bit for the sheet to close before showing the dialog
                  setTimeout(() => {
                    handleChangeResponsible(option.value);
                  }, 300);
                }
              }}
              placeholder="Ny ansvarig"
              className="w-full"
              zIndex={50}
              isDisabled={changingResponsible}
              isLoading={changingResponsible}
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Lägg till datum</label>
            <div className="relative">
              <Popover
                open={showMobileCalendar}
                onOpenChange={setShowMobileCalendar}
              >
                <PopoverTrigger asChild>
                  <Button variant="outline" className="w-full justify-center">
                    <PlusIcon className="mr-2 h-4 w-4" />
                    Lägg till datum
                  </Button>
                </PopoverTrigger>
                <div
                  className="fixed inset-0 z-50"
                  style={{
                    pointerEvents: showMobileCalendar ? "auto" : "none",
                  }}
                >
                  <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <PopoverContent
                      className="z-[100] shadow-lg"
                      sideOffset={5}
                    >
                      <Calendar
                        weekStartsOn={1}
                        locale={sv}
                        mode="single"
                        disabled={{ before: new Date() }}
                        onSelect={(d: Date | undefined) => {
                          if (d) {
                            handleAddDate(d);
                          }
                        }}
                      />
                    </PopoverContent>
                  </div>
                </div>
              </Popover>
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Granskningsintervall</label>
            <Dialog
              open={showRecurrenceDialog}
              onOpenChange={setShowRecurrenceDialog}
            >
              <DialogTrigger asChild>
                <Button variant="outline" className="w-full">
                  <RefreshCwIcon className="h-4 w-4 mr-2" />
                  Ställ granskningsintervall
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Ställ granskningsintervall</DialogTitle>
                </DialogHeader>
                <RecurrenceForm
                  disabled={false}
                  recurrenceConfig={recurrenceConfigDefaults}
                  onSave={(config) => handleSetInterval(config)}
                  savingStatus={savingStatus}
                />
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-1">
          {responsibleConfirmation.shouldSkipConfirmation() && (
            <Button
              onClick={resetDontAskAgainSetting}
              variant="outline"
              size="sm"
            >
              <Undo2Icon />
              Återställ bekräftelse-dialog för "ansvarig"
            </Button>
          )}
          {dateConfirmation.shouldSkipConfirmation() && (
            <Button
              onClick={resetDontAskAgainDateSetting}
              variant="outline"
              size="sm"
            >
              <Undo2Icon />
              Återställ bekräftelse-dialog för "datum"
            </Button>
          )}
          {intervalConfirmation.shouldSkipConfirmation() && (
            <Button
              onClick={resetDontAskAgainIntervalSetting}
              variant="outline"
              size="sm"
            >
              <Undo2Icon />
              Återställ bekräftelse-dialog för "intervall"
            </Button>
          )}
        </div>
        <SheetFooter className="mt-4 sm:mt-0 flex flex-col gap-2">
          <SheetClose asChild>
            <Button variant="ef" className="w-full py-6 text-lg font-medium">
              Stäng
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
