import Select from "react-select";

interface SelectPeopleProps {
  people: { id: number; name: string }[];
  value: { value: number; label: string }[] | { value: number; label: string } | null;
  onChange: (value: any) => void;
  placeholder?: string;
  className?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  zIndex?: number;
}

export default function SelectPeople({
  people,
  value,
  onChange,
  placeholder = "Ansvarig",
  className = "w-60",
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  zIndex = 10,
}: SelectPeopleProps) {
  return (
    <Select
      className={`${className} z-${zIndex}`}
      placeholder={placeholder}
      options={[
        ...people.map((person) => ({
          value: person.id,
          label: person.name,
        })),
        // Add "Nollställ" option at the bottom of the list
        { value: -1, label: "Nollställ" }
      ]}
      onChange={onChange}
      value={value}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isLoading={isLoading}
    />
  );
}