import Select from "react-select";

interface SelectFocusAreaProps {
  focusAreas: { id: number; name: string }[];
  value: { value: number; label: string }[];
  onChange: (value: any) => void;
  placeholder?: string;
  className?: string;
  zIndex?: number;
}

export default function SelectFocusArea({
  focusAreas,
  value,
  onChange,
  placeholder = "Sakområde",
  className = "w-60",
  zIndex = 10,
}: SelectFocusAreaProps) {
  return (
    <Select
      className={`${className} z-${zIndex}`}
      placeholder={placeholder}
      options={focusAreas.map((focusArea) => ({
        value: focusArea.id,
        label: focusArea.name,
      }))}
      onChange={onChange}
      value={value}
      isMulti
    />
  );
}