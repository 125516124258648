import {
  formatDateTime,
  getNextArticleDates,
} from "@/components/forms/RecurrenceForm/helpers/articleDates";
import type { Article } from "../../../hooks/api/types";
import NiceDateTime from "./components/NiceDateTime";
import ArticleStatus from "./components/ArticleStatus";
import { getArticleStatus, getStatusLabel } from "./utils/statusUtils";

export type ColumnDef = {
  id: string;
  header: string;
  data?: (row: Article, forSorting?: boolean) => string | undefined;
  render?: (row: Article) => JSX.Element | string | undefined;
};

export const columns: ColumnDef[] = [
  {
    id: "title",
    header: "Titel",
    render: (row: Article) => {
      const typeMap = {
        manual: "Manuell",
        semiManual: "Semi-manuell",
        automatic: "Automatisk",
      };
      const typeText = typeMap[row.apiConnectionType as keyof typeof typeMap] || "";
      
      return (
        <>
          <span className="cursor-context-menu">{row.title}</span>
          <a
            href={`${row.cueUrl}`}
            className="text-xs text-gray-600 ml-2 whitespace-nowrap"
          >
            Öppna i CUE
          </a>
          <div className="text-gray-600 text-sm">
            Sakområde: {row.focusAreaName}
          </div>
          <div className="text-gray-600 text-sm">
            Typ: {typeText}
          </div>
        </>
      );
    },
  },
  {
    id: "responsible",
    header: "Sidansvarig",
    data: (row: Article) => {
      return row?.pageManager?.name || "Saknas";
    },
    render: (row: Article) => {
      if (!row?.pageManager) {
        return <i className="text-gray-600">Saknas</i>;
      }
      
      return (
        <>
          {row.pageManager.name}
          <div className="text-xs text-gray-600 leading-3">
            <span>
              {row.pageManager.isPageManagerInheritedFromFocusArea &&
                "ärvd från sakområdet"}
            </span>
            {row.pageManager.isPageManagerInheritedFromFocusArea &&
              row.pageManager.isPageManagerForReview && <span> och </span>}
            <span>
              {row.pageManager.isPageManagerForReview &&
                "ställd som granskningsansvarig"}
            </span>
          </div>
        </>
      );
    },
  },
  {
    id: "status",
    header: "Status",
    data: (row: Article) => {
      const status = getArticleStatus(row);
      return getStatusLabel(status);
    },
    render: (row: Article) => <ArticleStatus article={row} />,
  },
  {
    id: "nextUpdate",
    header: "Nästa uppdatering",
    data: (row: Article, forSorting: boolean = false) => {
      const firstDate = getNextArticleDates({
        article: row,
        count: 1,
        usePostponedForSorting: true,
      })?.[0];
      const effectiveDate = firstDate?.postponedTo ?? firstDate?.date;
      if (forSorting) {
        return effectiveDate?.getTime().toString() ?? "-1";
      }
      return effectiveDate ? formatDateTime(effectiveDate) : "";
    },
    render: (row: Article) => {
      const firstDate = getNextArticleDates({
        article: row,
        count: 1,
        usePostponedForSorting: true,
      })?.[0];
      if (!firstDate) {
        return "";
      }
      const effectiveDate = firstDate?.postponedTo ?? firstDate?.date;
      return <NiceDateTime date={effectiveDate} />;
    },
  },
  {
    id: "lastReviewedAndUpdated",
    header: "Senast uppdaterad",
    data: (row: Article, forSorting: boolean = false) => {
      if (row.lastReview?.lastReviewTime) {
        if (forSorting) {
          return (
            (row.lastReview.lastReviewTime as Date).getTime().toString() ?? "-1"
          );
        }
        return typeof row.lastReview.lastReviewTime === "string"
          ? row.lastReview.lastReviewTime
          : formatDateTime(row.lastReview.lastReviewTime);
      }
      return "-1";
    },
    render: (row: Article) => {
      if (row.lastReview?.lastReviewTime) {
        const reviewTime = row.lastReview.lastReviewTime;
        const reviewDate =
          typeof reviewTime === "string" ? new Date(reviewTime) : reviewTime;
        return (
          <>
            <NiceDateTime date={reviewDate} />
            <div className="text-gray-600 text-xs">
              av {row.lastReview.person.name}
            </div>
          </>
        );
      }
    },
  },
  {
    id: "comment",
    header: "Kommentarer",
    data: (row: Article) => row.comment,
  },
];
