import Loading from "./Loading";
import React from "react";


const BlurryLoading = () => {
  return (
    <div className="absolute z-10 inset-0 -m-5 flex justify-center items-center backdrop-blur-sm">
      <Loading fullPageSpinner={false} />
    </div>
  )
};

export default BlurryLoading;
