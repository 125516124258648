import axiosClient from "@/config/axiosClient";
import type { Article } from "@/hooks/api/types";
import { isValid } from "date-fns";
import { useQuery } from "react-query";

interface MacrobondSeries {
  macrobondSeriesName: string;
  estimatedNextUpdate?: Date; // This is optional since it's not present in every object.
}

export interface Infographic {
  id: number;
  title: string;
  contentType: string;
  macrobondSeries: MacrobondSeries[];
}

type ArticleDetails = Article & {
  infographics?: Infographic[];
};

export function prepareArticle<T extends ArticleDetails | Article>(
  article: T,
): T {
  if ("infographics" in article) {
    article.infographics = article.infographics?.map((infographic) => {
      infographic.macrobondSeries = infographic.macrobondSeries.map(
        (macrobondSeries) => {
          if (!macrobondSeries.estimatedNextUpdate) {
            return macrobondSeries;
          }
          macrobondSeries.estimatedNextUpdate = new Date(
            macrobondSeries.estimatedNextUpdate,
          );
          return macrobondSeries;
        },
      );
      return infographic;
    });
  }

  if (article.reviewOccurrenceConfiguration?.startDate) {
    article.reviewOccurrenceConfiguration.startDate = new Date(
      article.reviewOccurrenceConfiguration.startDate,
    );
  }

  article.reviewOccurrenceExceptions = Object.fromEntries(
    Object.entries(article.reviewOccurrenceExceptions ?? {}).filter((entry) => {
      return isValid(new Date(entry[0]));
    }),
  );

  // Parse lastReviewTime to a Date object if it exists
  if (article.lastReview?.lastReviewTime) {
    article.lastReview.lastReviewTime = new Date(article.lastReview.lastReviewTime);
  }

  return article;
}

export async function getArticleDetails(articleId: number) {
  const data = (
    await axiosClient<{ article: ArticleDetails }>(
      `${process.env.BACKOFFICE_API_BASE_URL}/v2/up/getArticle?articleId=${articleId}`,
    )
  ).data;

  return prepareArticle(data.article);
}

export default function useArticleDetailsQuery(articleId: number) {
  return useQuery(
    ["articleDetails", articleId],
    () => getArticleDetails(articleId),
    {
      refetchOnWindowFocus: true,
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnMount: true,
    },
  );
}
