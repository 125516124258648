import { Skeleton } from "@/components/ui/skeleton";
import axiosClient from "@/config/axiosClient";
import { prepareArticle } from "@/hooks/api/useArticleDetailsQuery";
import { isValid } from "date-fns";
import { useEffect, useState } from "react";
import type { Article } from "../../../hooks/api/types";
import PlanerTable from "./PlannerTable";

export default function UpdatePlannerForm() {
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(`${process.env.BACKOFFICE_API_BASE_URL}/v2/up/listArticles`)
      .then((res) => {
        const articles = res?.data?.articles ?? [];
        articles.map((article: Article) => prepareArticle(article));
        setArticles(res?.data?.articles);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div>
        <h1>Publikationskalendern</h1>
        {loading && (
          <div className="flex flex-col gap-2">
            <Skeleton className="h-24" />
            <Skeleton className="h-16" />
            <Skeleton className="h-16" />
            <Skeleton className="h-16" />
            <Skeleton className="h-16" />
            <Skeleton className="h-16" />
          </div>
        )}
        <div className="-mx-3 2xl:mx-0">
          {!!articles && !loading && articles.length > 0 && (
            <PlanerTable articles={articles} />
          )}
        </div>
      </div>
    </>
  );
}
