import {
  describeRecurrenceOptions,
  getNextDatesFromConfig,
} from "@/components/forms/RecurrenceForm/helpers/generateNextDays";
import type { RecurrenceConfig } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import { isValid } from "date-fns";
import React from "react";

export default function RecurrenceDescription({
  repConfig,
  startDate = new Date(),
  nextDates = 5,
}: {
  repConfig: RecurrenceConfig;
  startDate?: Date;
  nextDates?: number;
}) {
  return (
    <>
      {describeRecurrenceOptions(repConfig)}
      {nextDates && (
        <div className="text-gray-600">
          5 nästkommande datum:
          <div className="grid">
            {getNextDatesFromConfig(repConfig, 5, startDate).map((d, i) => {
              if (!isValid(d)) {
                return <div key={i}>-</div>;
              }
              return (
                <div key={i}>
                  {d.toLocaleDateString(new Intl.Locale("sv-SE"), {
                    dateStyle: "long",
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
