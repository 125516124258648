import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "lucide-react";
import React from "react";

type Props = {
  startDate?: Date;
  onChange: (startDate: Date | undefined) => void;
};

export default function StartDatePopover({ startDate, onChange }: Props) {
  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            size="sm"
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal",
              !startDate && "text-muted-foreground",
            )}
          >
            <CalendarIcon />
            {startDate ? (
              startDate.toLocaleDateString("sv-SE", { dateStyle: "long" })
            ) : (
              <span>Startdatum</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            weekStartsOn={1}
            mode="single"
            selected={startDate}
            onSelect={(d) => onChange(d)}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </>
  );
}
