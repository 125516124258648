import { clsx, type ClassValue } from "clsx";
import { get } from "lodash";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function mutationToPromise(
  fn: (
    data: any,
    opts: { onSuccess: (value: unknown) => void; onError: () => void },
  ) => void,
  data: any,
) {
  return new Promise((resolve, reject) => {
    fn(data, {
      onSuccess: resolve,
      onError: reject,
    });
  });
}

export function mapDeep<T extends Object>(
  obj: T,
  path: string,
  fn: (value: any) => any,
): T {
  const keys = path.split(".");
  const key = keys.shift();
  if (!key) {
    return obj;
  }
  if (keys.length === 0) {
    return fn(get(obj, key));
  }
  return {
    ...obj,
    [key]: mapDeep(get(obj, key), keys.join("."), fn),
  };
}
