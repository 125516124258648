import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";

export const repeatFrequencies = {
  never: 'Inget intervall',
  daily: 'Upprepa dagligen',
  weekly: 'Upprepa veckovis',
  monthly: 'Upprepa månadsvis',
} as const;
export const repeatFrequencyValues = ['never', 'daily', 'weekly', 'monthly'] as const;

export type RepeatFrequency = keyof typeof repeatFrequencies;

export default function RepeatFrequencySelect({
  value,
  setValue,
  disabled
}: {
  value: RepeatFrequency,
  setValue: (v:RepeatFrequency) => void,
  disabled: boolean,
}) {
  return (
    <>
      <Select value={value} onValueChange={setValue} disabled={disabled}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Upprepa" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {repeatFrequencyValues.map(v => (
              <SelectItem key={v} value={v}>{repeatFrequencies[v]}</SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
}