import { Button } from "@/components/ui/button";
import { FilterIcon } from "lucide-react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import SelectPeople from "./SelectControls/SelectPeople";
import SelectStatus from "./SelectControls/SelectStatus";
import SelectFocusArea from "./SelectControls/SelectFocusArea";
import SelectContentType from "./SelectControls/SelectContentType";

interface FilterControlsProps {
  people: { id: number; name: string }[];
  statuses: string[];
  focusAreas: { id: number; name: string }[];
  contentTypes: { value: string; label: string }[];
  selectedResponsiblePeople: { value: number; label: string }[];
  setSelectedResponsiblePeople: (
    people: { value: number; label: string }[],
  ) => void;
  selectedStatuses: { value: string; label: string }[];
  setSelectedStatuses: (statuses: { value: string; label: string }[]) => void;
  selectedFocusAreas: { value: number; label: string }[];
  setSelectedFocusAreas: (areas: { value: number; label: string }[]) => void;
  selectedContentTypes: { value: string; label: string }[];
  setSelectedContentTypes: (types: { value: string; label: string }[]) => void;
  filteredArticlesCount: number;
}

export default function FilterControls({
  people,
  statuses,
  focusAreas,
  contentTypes,
  selectedResponsiblePeople,
  setSelectedResponsiblePeople,
  selectedStatuses,
  setSelectedStatuses,
  selectedFocusAreas,
  setSelectedFocusAreas,
  selectedContentTypes,
  setSelectedContentTypes,
  filteredArticlesCount,
}: FilterControlsProps) {
  // Check if any filters are active
  const hasActiveFilters =
    selectedResponsiblePeople.length > 0 ||
    selectedStatuses.length > 0 ||
    selectedFocusAreas.length > 0 ||
    selectedContentTypes.length > 0;

  return (
    <>
      {/* Desktop filter section (hidden on screens < 1700px) */}
      <div className="hidden 2xl:block [min-width:1700px]:block">
        <span className="block text-lg font-bold">Filtrera</span>
        <div className="flex items-center gap-2">
          <SelectPeople
            people={people}
            value={selectedResponsiblePeople}
            onChange={(options) => setSelectedResponsiblePeople([...options])}
            isMulti
          />
          <SelectStatus
            statuses={statuses}
            value={selectedStatuses}
            onChange={(options) => setSelectedStatuses([...options])}
          />
          <SelectFocusArea
            focusAreas={focusAreas}
            value={selectedFocusAreas}
            onChange={(options) => setSelectedFocusAreas([...options])}
          />
          <SelectContentType
            contentTypes={contentTypes}
            value={selectedContentTypes}
            onChange={(options) => setSelectedContentTypes([...options])}
          />
        </div>
      </div>

      {/* Mobile filter button and sheet (visible on screens < 1700px) */}
      <div className="block 2xl:hidden [min-width:1700px]:hidden">
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              className="flex items-center gap-2 relative"
            >
              <FilterIcon className="h-4 w-4" />
              Filtrera
              {/* Red dot indicator when filters are active */}
              {hasActiveFilters && (
                <span className="absolute -top-1 -right-1 h-3 w-3 bg-red-500 rounded-full" />
              )}
            </Button>
          </SheetTrigger>
          <SheetContent side="right" className="w-[350px] sm:w-[450px] flex flex-col">
            <SheetHeader>
              <SheetTitle>Filtrera</SheetTitle>
            </SheetHeader>
            <div className="flex flex-col gap-4 py-4 flex-grow overflow-y-auto">
              <div className="space-y-2">
                <label className="text-sm font-medium">Ansvarig</label>
                <SelectPeople
                  people={people}
                  value={selectedResponsiblePeople}
                  onChange={(options) => setSelectedResponsiblePeople([...options])}
                  className="w-full"
                  zIndex={50}
                  isMulti
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Status</label>
                <SelectStatus
                  statuses={statuses}
                  value={selectedStatuses}
                  onChange={(options) => setSelectedStatuses([...options])}
                  className="w-full"
                  zIndex={40}
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Sakområde</label>
                <SelectFocusArea
                  focusAreas={focusAreas}
                  value={selectedFocusAreas}
                  onChange={(options) => setSelectedFocusAreas([...options])}
                  className="w-full"
                  zIndex={30}
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Innehållstyp</label>
                <SelectContentType
                  contentTypes={contentTypes}
                  value={selectedContentTypes}
                  onChange={(options) => setSelectedContentTypes([...options])}
                  className="w-full"
                  zIndex={20}
                />
              </div>
            </div>
            <SheetFooter className="mt-4 sm:mt-0">
              <SheetClose asChild>
                <Button variant="ef" className="w-full py-6 text-lg font-medium">
                  Visa resultat ({filteredArticlesCount})
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
      </div>
    </>
  );
}