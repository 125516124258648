import { useForm } from 'react-hook-form'
import useCoWorkerFormMutation from '../../hooks/api/useCoWorkerFormMutation'
import ButtonOld from '../ui/ButtonOld'
import Input from '../ui/Input'
import TextArea from '../ui/TextArea'
import z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import useCoWorkerFormQuery from '../../hooks/api/useCoWorkerFormQuery'
import {useEffect, useState} from 'react'
import {getFriendlyErrorMessage} from "../../utils/errorCodeMessages";
import type {BaseEndpointErrorResponse} from "../../hooks/api/types";
import SNToast from "../SNToast";
import BlurryLoading from "../BlurryLoading";

const formSchema = z.object({
  job: z.object({
    title: z.object({
      sv: z.string().min(1, { message: 'Jobbtitel är obligatoriskt' }),
      en: z.string().min(1, { message: 'Jobbtitel är obligatoriskt' }),
    }),
    description: z.object({
      sv: z.string().min(1, { message: 'Jobbeskrivning är obligatoriskt' }),
      en: z.string().min(1, { message: 'Jobbeskrivning är obligatoriskt' }),
    }),
  }),
  phone: z.object({
    work: z.object({
      sv: z.string().min(1, { message: 'Telefonnummer är obligatoriskt' }),
      en: z.string().optional(),
    }),
    cell: z.object({
      sv: z.string().optional(),
      en: z.string().optional(),
    }),
  }),
  visibility: z.object({
    show: z.object({
      mobile: z.boolean().optional(),
    }),
    hide: z.object({
      phone: z.boolean().optional(),
    }),
  })
})

type UserProfileFormData = z.infer<typeof formSchema>

const UserProfileForm = () => {
  const [response, setResponse] = useState<BaseEndpointErrorResponse | null>(null);
  const mutation = useCoWorkerFormMutation()
  const query = useCoWorkerFormQuery()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserProfileFormData>({
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    if (query.status === 'error') {
      // @ts-ignore
      setResponse(query?.error?.response?.data)
    }
    if (query.status !== 'success') {
      return
    }
    reset({
      job: {
        title: {
          sv: query.data?.profileArticle.fields.assignment || '',
          en: query.data?.profileArticle.fields.assignmentEng || '',
        },
        description: {
          sv: query.data?.profileArticle.fields.description || '',
          en: query.data?.profileArticle.fields.descriptionEng || '',
        },
      },
      phone: {
        work: {
          sv: query.data?.profileArticle.fields.phoneWorkDirect || '',
          en: query.data?.profileArticle.fields.phoneWorkDirectEng || '',
        },
        cell: {
          sv: query.data?.profileArticle.fields.phoneMobile || '',
          en: query.data?.profileArticle.fields.phoneMobileEng || '',
        },
      },
      visibility: {
        show: {
          mobile: query.data?.profileArticle.fields.showMobile ?? false
        },
        hide: {
          phone: query.data?.profileArticle.fields.hidePhone ?? false
        }
      }
    })
  }, [query.status])

  const onSubmit = handleSubmit(formData => {
    if (!query.data) {
      console.log('no initial form data')
      return
    }

    const payload = {
      profileArticle: {
        id: query.data.profileArticle.id,
        fields: {
          assignment: formData.job.title.sv,
          assignmentEng: formData.job.title.en,
          description: formData.job.description.sv,
          descriptionEng: formData.job.description.en,
          phoneMobile: formData.phone.cell.sv,
          phoneMobileEng: formData.phone.cell.en,
          phoneWorkDirect: formData.phone.work.sv,
          phoneWorkDirectEng: formData.phone.work.en,
          showMobile: formData.visibility.show.mobile,
          hidePhone: formData.visibility.hide.phone,
        },
      },
    }
    mutation.mutate(payload as any)
  })

  return (
    <>
      <form onSubmit={onSubmit} className="relative mt-6">
        {query.status === 'loading' && <BlurryLoading />}
        {response && response.errorCode.length > 0 && (
          <div className="flex justify-center">
            <SNToast
              type={'error'}
              title={getFriendlyErrorMessage(response.errorCode)}
            />
          </div>
        )}
        {(query.status === 'loading' || query.status === 'success') && (
          <>
            <h1>Din medarbetarsida</h1>
            <p>Här kan du uppdatera din titel, arbetsbeskrivning och telefonnummer som visas på <a href="https://www.svensktnaringsliv.se" target="_blank">svensktnaringsliv.se</a>.</p>
            <p>Fälten för "Engelskt telefonnummer" ska normalt sett vara tomma. Fyll bara i något här om du vill visa ett annat telefonnummer på den engelska delen av <a href="https://www.svensktnaringsliv.se" target="_blank">svensktnaringsliv.se</a> jämfört med den svenska delen. Detta gäller främst medarbetare i Bryssel.</p>
            <div className="grid grid-cols-fluid gap-4 mt-6">
              <div>
                <label htmlFor="jobTitle">
                  <h3 className={clsx('required-field', { 'text-red-600': errors?.job?.title?.sv })}>Jobbtitel (Svenska)</h3>
                </label>
                <Input
                  {...register('job.title.sv')}
                  id="jobTitle"
                  placeholder="Ange svensk jobbtitel"
                  className={clsx({
                    'ring-red-400/40 ring-4': errors?.job?.title?.sv,
                  })}
                />
                {errors?.job?.title?.sv && (
                  <span className="text-red-500 text-sm mt-2 ml-1 block">{errors.job.title.sv.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="job-title-en">
                  <h3 className={clsx('required-field', { 'text-red-600': errors?.job?.title?.en })}>Jobbtitel (Engelska)</h3>
                </label>
                <Input
                  {...register('job.title.en')}
                  id="job-title-en"
                  placeholder="Ange engelsk jobbtitel"
                  className={clsx({
                    'ring-red-400/40 ring-4': errors?.job?.title?.en,
                  })}
                />
                {errors?.job?.title?.en && (
                  <span className="text-red-500 text-sm mt-2 ml-1 block">{errors.job.title.en.message}</span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-fluid gap-4 mt-6">
              <div>
                <label htmlFor="job-description-sv">
                  <h3 className={clsx('required-field', { 'text-red-600': errors?.job?.description?.sv })}>
                    Jobbeskrivning (Svenska)
                  </h3>
                </label>
                <TextArea
                  {...register('job.description.sv')}
                  id="job-description-sv"
                  placeholder="Ange en jobbeskrivning på svenska"
                  className={clsx({
                    'ring-red-400/40 ring-4': errors?.job?.description?.sv,
                  })}
                />
                {errors?.job?.description?.sv && (
                  <span className="text-red-500 text-sm mt-2 ml-1 block">{errors.job.description.sv.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="job-description-en">
                  <h3 className={clsx('required-field', { 'text-red-600': errors?.job?.description?.en })}>
                    Jobbeskrivning (Engelska)
                  </h3>
                </label>
                <TextArea
                  {...register('job.description.en')}
                  id="job-description-en"
                  placeholder="Ange en jobbeskrivning på engelska"
                  className={clsx({
                    'ring-red-400/40 ring-4': errors?.job?.description?.en,
                  })}
                />
                {errors?.job?.description?.en && (
                  <span className="text-red-500 text-sm mt-2 ml-1 block">{errors.job.description.en.message}</span>
                )}
              </div>
            </div>
            <div className="mt-6 grid grid-cols-fluid gap-4">
              <div>
                <label htmlFor="phone-work-sv">
                  <h3
                    className={clsx('required-field', {
                      'text-red-600': errors?.phone?.work?.sv,
                    })}
                  >
                    Telefonnummer Arbete (Svenska)
                  </h3>
                </label>
                <Input
                  {...register('phone.work.sv')}
                  id="phone-work-sv"
                  placeholder="070 000 00 00"
                  className={clsx({
                    'ring-red-400/40 ring-4': errors?.phone?.work?.sv,
                  })}
                />
                {errors?.phone?.work?.sv && (
                  <span className="text-red-500 text-sm mt-2 ml-1 block">{errors.phone.work.sv.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="phone-work-en">
                  <h3 className={clsx({ 'text-red-600': errors?.phone?.work?.en })}>
                    Telefonnummer Arbete (Engelska)
                  </h3>
                </label>
                <Input
                  {...register('phone.work.en')}
                  id="phone-work-en"
                  placeholder="070 000 00 00"
                  className={clsx({
                    'ring-red-400/40 ring-4': errors?.phone?.work?.en,
                  })}
                />
                {errors?.phone?.work?.en && (
                  <span className="text-red-500 text-sm mt-2 ml-1 block">{errors.phone.work.en.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="phone-cell-sv">
                  <h3 className={clsx({ 'text-red-600': errors?.phone?.cell?.sv })}>
                    Telefonnummer Mobil (Svenska)
                  </h3>
                </label>
                <Input
                  {...register('phone.cell.sv')}
                  id="phone-cell-sv"
                  placeholder="070 000 00 00"
                  className={clsx({
                    'ring-red-400/40 ring-4': errors?.phone?.cell?.sv,
                  })}
                />
                {errors?.phone?.cell?.sv && (
                  <span className="text-red-500 text-sm mt-2 ml-1 block">{errors.phone.cell.sv.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="phone-cell-en">
                  <h3 className={clsx({ 'text-red-600': errors?.phone?.cell?.en })}>
                    Telefonnummer Mobil (Engelska)
                  </h3>
                </label>
                <Input
                  {...register('phone.cell.en')}
                  id="phone-cell-en"
                  placeholder="070 000 00 00"
                  className={clsx({
                    'ring-red-400/40 ring-4': errors?.phone?.cell?.en,
                  })}
                />
                {errors?.phone?.cell?.en && (
                  <span className="text-red-500 text-sm mt-2 ml-1 block">{errors.phone.cell.en.message}</span>
                )}
              </div>
            </div>
            <div className="mt-6">
              <h2>Visa eller dölj telefonnummer</h2>
              <p>Som förvalt visas enbart ditt fasta telefonnummer (det som går via växeln och börjar på ditt lokala riktnummer). Nedan väljer du vilka telefonnummer som ska visas på webbplatsen.</p>
              <label htmlFor="visibility-show-mobile" className="mt-6">
                <Input
                  {...register('visibility.show.mobile')}
                  type="checkbox"
                  id="visibility-show-mobile"
                />
                Visa mobilnummer
              </label>
              <label htmlFor="visibility-hide-phone" className="mt-3">
                <Input
                  {...register('visibility.hide.phone')}
                  type="checkbox"
                  id="visibility-hide-phone"
                />
                Dölj fast telefonnummer
              </label>
            </div>
            <ButtonOld disabled={mutation.isLoading || query.isLoading} className="mt-6" type="submit">
              Spara
            </ButtonOld>
          </>
        )}
      </form>
    </>
  )
}

export default UserProfileForm
