import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { sv } from "date-fns/locale";
import { PlusIcon } from "lucide-react";
import React from "react";
import type { ComponentProps } from "react";

type Props = ComponentProps<typeof Popover> & {
  buttonDisabled?: boolean;
  onSelect: (date: Date) => void;
  disabledDates?: ComponentProps<typeof Calendar>["disabled"];
};

export default function AddDatePopover(props: Props) {
  return (
    <>
      <Popover open={props.open} onOpenChange={props.onOpenChange}>
        <PopoverTrigger asChild>
          <Button variant="outline" disabled={props.buttonDisabled}>
            <PlusIcon />
            Lägg till datum
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <Calendar
            weekStartsOn={1}
            locale={sv}
            mode="single"
            disabled={props.disabledDates}
            onSelect={(d: Date | undefined) => {
              if (d) {
                props.onSelect(d);
              }
            }}
          />
        </PopoverContent>
      </Popover>
    </>
  );
}
