import IndeterminateCheckbox from "../../ui/IndeterminateCheckbox";
import { uniqBy } from "lodash";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import type { Article } from "../../../hooks/api/types";
import TablePagination from "../../ui/TablePagination";
import ArticleDialog from "./components/ArticleDialog";
import PlannerTableControls from "./components/PlannerTableControls";
import {
  getArticleStatus,
  getStatusLabel,
  statusConfigs,
  StatusConfig,
} from "./utils/statusUtils";
import { columns, ColumnDef } from "./columnDefinitions";

export default function PlannerTable({ articles }: { articles: Article[] }) {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [sortByColumnId, setSortByColumnId] = useState<string | null>(
    "nextUpdate",
  );
  const [sortDesc, setSortDesc] = useState<boolean>(false);
  const [activeSearchPhrase, setActiveSearchPhrase] = useState("");
  const [selectedResponsiblePeople, setSelectedResponsiblePeople] = useState<
    { value: number; label: string }[]
  >([]);
  const [selectedStatuses, setSelectedStatuses] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<
    { value: number; label: string }[]
  >([]);
  const [selectedContentTypes, setSelectedContentTypes] = useState<
    { value: string; label: string }[]
  >([]);
  const [activeArticle, setActiveArticle] = useState<Article | null>(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  // Load page size from localStorage on component mount
  useEffect(() => {
    const savedPageSize = localStorage.getItem("plannerTablePageSize");
    if (savedPageSize) {
      setPageSize(parseInt(savedPageSize, 10));
    }
  }, []);

  // Save page size to localStorage when it changes
  useEffect(() => {
    localStorage.setItem("plannerTablePageSize", pageSize.toString());
  }, [pageSize]);

  function toggleRowSelected(id: number) {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  }

  const getColumnData = useCallback(
    (row: Article, columnId: string, forSorting: boolean = false) => {
      const column = columns.find((c: ColumnDef) => c.id === columnId);
      const dataFn = column?.data;
      const data = dataFn
        ? dataFn(row, forSorting)
        : row[columnId as keyof Article];
      if (!data) {
        return "";
      }
      if (typeof data === "object") {
        return JSON.stringify(data);
      }
      return data.toString();
    },
    [],
  );

  const toggleSortByColumn = (columnId: keyof Article) => {
    if (sortByColumnId === columnId) {
      if (sortDesc) {
        setSortByColumnId(null);
      } else {
        setSortDesc(true);
      }
    } else {
      setSortByColumnId(columnId);
      setSortDesc(false);
    }
  };

  articles.sort((a, b) => {
    if (!sortByColumnId) return 0;
    const dataA = getColumnData(a, sortByColumnId, true);
    const dataB = getColumnData(b, sortByColumnId, true);
    if (/^-?[0-9]+$/.test(dataA) && /^-?[0-9]+$/.test(dataB)) {
      const dateA = parseFloat(dataA);
      const dateB = parseFloat(dataB);
      if (dateA === -1) {
        return 1;
      }
      if (dateB === -1) {
        return -1;
      }
      return sortDesc ? dateB - dateA : dateA - dateB;
    }
    return sortDesc ? dataB.localeCompare(dataA) : dataA.localeCompare(dataB);
  });

  // Create a list of unique page managers, including a special entry for missing ones
  const people = uniqBy(
    articles
      .map((article) => article.pageManager)
      .filter((person) => person?.id && person?.name)
      .map((p) => ({ id: p?.id, name: p?.name })),
    (p) => p.id,
  ) as { id: number; name: string }[];

  // Add a special entry for missing page managers if there are any articles without a page manager
  const hasMissingPageManagers = articles.some(
    (article) => !article.pageManager,
  );
  if (hasMissingPageManagers) {
    people.push({ id: -1, name: "Saknas" });
  }

  const focusAreas = uniqBy(
    articles
      .filter((article) => article.focusAreaId && article.focusAreaName)
      .map((article) => ({
        id: article.focusAreaId,
        name: article.focusAreaName,
      })),
    (p) => p.id,
  ) as { id: number; name: string }[];

  const statuses = Object.values(statusConfigs).map(
    (config: StatusConfig) => config.label,
  );

  // Extract unique content types from articles
  const contentTypes = uniqBy(
    articles
      .filter((article) => article.contentType && article.contentTypeLabel)
      .map((article) => ({
        value: article.contentType,
        label: article.contentTypeLabel,
      })),
    (ct) => ct.value,
  );

  const filteredArticles = articles.filter((a) => {
    if (activeSearchPhrase !== "") {
      const data = columns
        .map((c: ColumnDef) => getColumnData(a, c.id as keyof Article))
        .filter((d: string) => !!d);
      const phrases = activeSearchPhrase
        .split(" ")
        .filter((p: string) => !!p)
        .map((p: string) => p.toLowerCase());
      for (const phrase of phrases) {
        const phraseInData = data.some((d: string) =>
          d.toLowerCase().includes(phrase),
        );
        if (!phraseInData) {
          return false;
        }
      }
    }

    if (selectedResponsiblePeople.length > 0) {
      const selectedValues = selectedResponsiblePeople.map((o) => o.value);

      // Check if the special "Saknas" option is selected
      const isMissingSelected = selectedValues.includes(-1);

      if (!a?.pageManager) {
        // If the article has no page manager, include it only if "Saknas" is selected
        return isMissingSelected;
      }

      // For articles with page managers, include if their ID is in the selected values
      if (!selectedValues.includes(a.pageManager.id)) {
        return false;
      }
    }

    if (selectedStatuses.length > 0) {
      const status = getArticleStatus(a);
      const statusLabel = getStatusLabel(status);

      if (!selectedStatuses.map((o) => o.value).includes(statusLabel)) {
        return false;
      }
    }

    if (a.focusAreaId && selectedFocusAreas.length > 0) {
      if (!selectedFocusAreas.map((o) => o.value).includes(a.focusAreaId)) {
        return false;
      }
    }

    if (selectedContentTypes.length > 0) {
      if (!selectedContentTypes.map((o) => o.value).includes(a.contentType)) {
        return false;
      }
    }

    return true;
  });

  // Pagination logic
  const pageCount =
    pageSize === 0 ? 1 : Math.ceil(filteredArticles.length / pageSize);

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(0);
  }, [
    activeSearchPhrase,
    selectedResponsiblePeople,
    selectedStatuses,
    selectedFocusAreas,
    selectedContentTypes,
  ]);

  // Get current page items
  const currentPageItems = useMemo(() => {
    if (pageSize === 0) return filteredArticles; // Show all items
    const startIndex = currentPage * pageSize;
    return filteredArticles.slice(startIndex, startIndex + pageSize);
  }, [filteredArticles, currentPage, pageSize]);

  // Pagination handlers
  const gotoPage = (page: number) => {
    setCurrentPage(page);
  };

  const previousPage = () => {
    setCurrentPage((old) => Math.max(0, old - 1));
  };

  const nextPage = () => {
    setCurrentPage((old) => Math.min(pageCount - 1, old + 1));
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(0); // Reset to first page when changing page size
  };

  return (
    <div>
      <ArticleDialog
        activeArticle={activeArticle}
        setActiveArticle={setActiveArticle}
      />
      <div className="max-h-full">
        <PlannerTableControls
          articles={articles}
          filteredArticles={filteredArticles}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          setActiveSearchPhrase={setActiveSearchPhrase}
          selectedResponsiblePeople={selectedResponsiblePeople}
          setSelectedResponsiblePeople={setSelectedResponsiblePeople}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          selectedFocusAreas={selectedFocusAreas}
          setSelectedFocusAreas={setSelectedFocusAreas}
          selectedContentTypes={selectedContentTypes}
          setSelectedContentTypes={setSelectedContentTypes}
          people={people}
          statuses={statuses}
          focusAreas={focusAreas}
          contentTypes={contentTypes}
        />
        <table className="styled-table max-w-full">
          <thead className="sticky top-16 2xl:top-24">
            <tr>
              <th>
                <IndeterminateCheckbox
                  type="checkbox"
                  checked={selectedIds.length > 0}
                  onChange={() =>
                    selectedIds.length === filteredArticles.length
                      ? setSelectedIds([])
                      : setSelectedIds(filteredArticles.map((item) => item.id))
                  }
                  indeterminate={
                    selectedIds.length > 0 &&
                    selectedIds.length < filteredArticles.length
                  }
                />
              </th>
              {columns.map((column: ColumnDef) => (
                <th
                  key={column.id}
                  onClick={() => toggleSortByColumn(column.id as keyof Article)}
                >
                  <div className="flex items-center cursor-pointer">
                    {column.header}
                    {sortByColumnId !== column.id && (
                      <FaSort className="ml-2" size={16} color="#aaa" />
                    )}
                    {sortByColumnId === column.id && !sortDesc && (
                      <FaSortUp className="ml-2" size={16} />
                    )}
                    {sortByColumnId === column.id && sortDesc && (
                      <FaSortDown className="ml-2" size={16} />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentPageItems.map((article, i) => (
              <tr key={i}>
                <td>
                  <input
                    type="checkbox"
                    value={article.id}
                    checked={selectedIds.includes(article.id)}
                    onChange={() => toggleRowSelected(article.id)}
                  />
                </td>
                {columns.map((column: ColumnDef) => (
                  <td key={column.id}>
                    <span
                      onClick={() => {
                        if (column.id === "title") {
                          setActiveArticle(article);
                        }
                      }}
                    >
                      {column.render
                        ? column.render(article)
                        : getColumnData(article, column.id as keyof Article)}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot className="sticky bottom-0 border-t px-2 py-1 bg-slate-200/90 text-left backdrop-blur supports-[backdrop-filter]:bg-slate-200/60">
            <tr>
              <td colSpan={columns.length + 1}>
                <div className="flex justify-between items-center py-1 px-4">
                  <div className="w-1/3"></div>
                  <div className="w-1/3 flex justify-center items-center">
                    <div className="flex items-center">
                      <TablePagination
                        canPreviousPage={currentPage > 0}
                        canNextPage={currentPage < pageCount - 1}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        currentPage={currentPage}
                      />
                    </div>
                  </div>
                  <div className="w-1/3 flex justify-end">
                    <div className="flex items-center">
                      <span className="mr-2">Rader per sida:</span>
                      <select
                        value={pageSize}
                        onChange={(e) =>
                          handlePageSizeChange(Number(e.target.value))
                        }
                        className="p-1 border rounded"
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={0}>Alla</option>
                      </select>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
