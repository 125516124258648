import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import type {ComponentProps} from "react";

export const weekdayNumbers = [0, 1, 2, 3, 4, 5, 6] as const;
export type WeekdayNumber = typeof weekdayNumbers[number];

export default function MonthlyWeekdaySelect(props: ComponentProps<typeof Select>) {
  return (
    <>
      <Select {...props}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Vilken dag" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="day">dagen</SelectItem>
            <SelectItem value="1">måndagen</SelectItem>
            <SelectItem value="2">tisdagen</SelectItem>
            <SelectItem value="3">onsdagen</SelectItem>
            <SelectItem value="4">torsdagen</SelectItem>
            <SelectItem value="5">fredagen</SelectItem>
            <SelectItem value="6">lördagen</SelectItem>
            <SelectItem value="0">söndagen</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
}