import type { Article } from "@/hooks/api/types";
import { cn } from "@/lib/utils";
import { getArticleStatus, statusConfigs } from "../utils/statusUtils";

export default function ArticleStatus({ article }: { article: Article }) {
  const status = getArticleStatus(article);
  const { label } = statusConfigs[status];

  return (
    <div
      className={cn(
        "rounded-full px-2 py-1 my-1 text-center",
        status === "kommande" && "bg-gray-200 text-gray-800",
        status === "uppdaterad" && "bg-green-200 text-green-800",
        status === "uppdatera" && "bg-yellow-200 text-yellow-800",
        status === "uppdaterad_ej_publicerad" &&
          "bg-purple-200 text-purple-800",
        status === "forsenad" && "bg-red-200 text-red-800",
      )}
    >
      {label}
    </div>
  );
}
