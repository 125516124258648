import { formatDateTime } from "@/components/forms/RecurrenceForm/helpers/articleDates";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Infographic } from "@/hooks/api/useArticleDetailsQuery";
import React from "react";

export default function MacrobondTable({
  isLoading,
  infographics,
}: {
  isLoading: boolean;
  infographics: Infographic[];
}) {
  return (
    <>
      <ScrollArea>
        <div className="max-h-52">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Rubrik</TableHead>
                <TableHead>Macrobond</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={2} className="p-0 pt-1">
                    <div className="flex flex-col gap-0.5">
                      <Skeleton className="w-full h-10" />
                      <Skeleton className="w-full h-10" />
                      <Skeleton className="w-full h-10" />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {infographics &&
                    infographics.map((infographic, i) => (
                      <TableRow key={i}>
                        <TableCell>{infographic.title}</TableCell>
                        <TableCell className="py-1.5">
                          {infographic.macrobondSeries.length > 0 ? (
                            <div>
                              {infographic.macrobondSeries.map(
                                (macrobondSeries, i) => (
                                  <div key={i}>
                                    <div className="">
                                      {macrobondSeries.macrobondSeriesName}
                                    </div>
                                    {macrobondSeries.estimatedNextUpdate && (
                                      <div className="text-gray-600 text-sm -mt-1 mb-1">
                                        {" "}
                                        nästa uppdatering:{" "}
                                        {formatDateTime(
                                          macrobondSeries.estimatedNextUpdate,
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ),
                              )}
                            </div>
                          ) : (
                            <div>Nej</div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </div>
      </ScrollArea>
    </>
  );
}
