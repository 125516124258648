import { useState } from "react";

interface UseConfirmationOptions {
  skipConfirmationKey: string;
  onExecute: (value: any) => void;
}

export default function useConfirmation<T>({
  skipConfirmationKey,
  onExecute,
}: UseConfirmationOptions) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingValue, setPendingValue] = useState<T | null>(null);

  // Check if we should skip confirmations based on localStorage
  const shouldSkipConfirmation = () => {
    return localStorage.getItem(skipConfirmationKey) === "true";
  };

  // Reset the "don't ask again" setting
  const resetDontAskAgainSetting = () => {
    localStorage.removeItem(skipConfirmationKey);
  };

  // Handle action with confirmation
  const handleAction = (value: T) => {
    // If we should skip confirmation, proceed directly
    if (shouldSkipConfirmation()) {
      onExecute(value);
      return;
    }

    // Store the pending value and show the confirmation dialog
    setPendingValue(value);
    setShowConfirmDialog(true);
  };

  // Handle confirmation dialog confirm button
  const handleConfirm = () => {
    if (pendingValue !== null) {
      // Execute the action
      onExecute(pendingValue);

      // Close the dialog and reset state
      setShowConfirmDialog(false);
      setPendingValue(null);
    }
  };

  // Handle confirmation dialog cancel button
  const handleCancel = () => {
    setShowConfirmDialog(false);
    setPendingValue(null);
  };

  return {
    showConfirmDialog,
    setShowConfirmDialog,
    pendingValue,
    shouldSkipConfirmation,
    resetDontAskAgainSetting,
    handleAction,
    handleConfirm,
    handleCancel,
  };
}