import { formatDateTime } from "@/components/forms/RecurrenceForm/helpers/articleDates";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import sv from "timeago.js/lib/lang/sv";

timeago.register("sv", sv);

export default function NiceDateTime({ date }: { date: Date }) {
  return (
    <>
      <TimeAgo datetime={date} locale="sv" />
      <div className="text-gray-600 text-sm">{formatDateTime(date)}</div>
    </>
  );
}