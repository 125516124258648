import type { WeekdayNumber } from "@/components/forms/RecurrenceForm/FormFields/MonthlyWeekdaySelect";
import type { RepeatFrequency } from "@/components/forms/RecurrenceForm/FormFields/RepeatFrequencySelect";

export interface RecurrenceConfig {
  repeatFrequency?: RepeatFrequency;
  dailyInterval?: number;
  weeklyInterval?: number;
  weeklyDays?: WeekdayNumber[]; // 0 (Sunday) to 6 (Saturday)
  monthlyInterval?: number;
  monthlyRepeatMode?: "byMonthDays" | "byWeekdayOccurrence";
  selectedMonthDays?: number[];
  monthlyOccurrence?:
    | "first"
    | "second"
    | "third"
    | "fourth"
    | "fifth"
    | "last";
  monthlyWeekday?: "day" | WeekdayNumber; // 'day' or 0 (Sunday) to 6 (Saturday)
  desiredTime?: string; // 12 or 23 etc
  startDate?: Date;
}

export const monthlyOccurrences = {
  first: "första",
  second: "andra",
  third: "tredje",
  fourth: "fjärde",
  fifth: "femte",
  last: "sista",
} as const;

export type MonthlyOccurrence = keyof typeof monthlyOccurrences;
