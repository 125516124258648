import clsx from 'clsx'
import type { ButtonHTMLAttributes, ReactNode } from 'react'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode
  classNAme?: string
  size?: 's' | 'm' | 'l'
}

const ButtonOld = ({ children, type = 'button', className = '', size = 'm', ...restProps }: Props) => {
  return (
    <button
      type={type}
      className={clsx(
        'bg-cta-primary hover:bg-cta-primary-hover transition-colors text-white text-base disabled:bg-cta-disabled disabled:opacity-60 disabled:cursor-not-allowed',
        {
          [className]: !!className,
          'py-1 px-2': size === 's',
          'py-3 px-4': size === 'm',
          'py-4 px-5 text-lg': size === 'l',
        }
      )}
      {...restProps}
    >
      {children}
    </button>
  )
}

export default ButtonOld
