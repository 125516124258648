import { getNextDatesFromConfig } from "@/components/forms/RecurrenceForm/helpers/generateNextDays";
import type { RecurrenceConfig } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import type { Article } from "@/hooks/api/types";
import { isAfter, isBefore, setHours, setMinutes, setSeconds } from "date-fns";
import { sortBy, uniqBy } from "lodash";

export function setTimeFromConfig(
  date: Date,
  options: RecurrenceConfig | undefined,
): Date {
  const twoCharTime = (options?.desiredTime || "08")?.slice(0, 2);
  return setSeconds(setMinutes(setHours(date, Number(twoCharTime)), 0), 0);
}

export function formatDateTime(date: Date) {
  return (
    date.toLocaleDateString("sv-SE", { dateStyle: "long" }) +
    " " +
    date.toLocaleTimeString("sv-SE", { timeStyle: "short" })
  );
}

export function earliestDate(...dates: (Date | undefined)[]): Date | undefined {
  dates = dates.filter((d) => !!d);
  return sortBy(dates, (d) => d?.getTime())[0];
}

export function latestDate(...dates: (Date | undefined)[]): Date | undefined {
  dates = dates.filter((d) => !!d);
  return sortBy(dates as Date[], (d) => d.getTime())[dates.length - 1];
}

export function getArticleDatesStartingPoint(article: Article) {
  const latestReview = article.lastReview?.lastReviewTime
    ? new Date(article.lastReview?.lastReviewTime)
    : undefined;
  if (latestReview) {
    return latestReview;
  }
  const intervalStartDate = article.reviewOccurrenceConfiguration?.startDate;
  const exceptionDates = [
    ...Object.keys(article.reviewOccurrenceExceptions).map((d) => new Date(d)),
    ...Object.values(article.reviewOccurrenceExceptions)
      .filter((d) => typeof d === "string")
      .map((d) => new Date(typeof d === "string" ? d : 0)),
  ];
  return earliestDate(intervalStartDate, ...exceptionDates) ?? new Date();
}

export type ArticleDate = {
  date: Date;
  isGenerated: boolean;
  isCancelled: boolean;
  replaces?: Date;
  postponedTo?: Date;
};

function pushOrReplaceDate(dates: ArticleDate[], newDate: ArticleDate) {
  let replaced = false;
  for (const dateKey in dates) {
    const d = dates[dateKey];
    const same = d.date.toString() === newDate.date.toString();
    if (same) {
      dates[dateKey] = { ...newDate, replaces: d.date };
      replaced = true;
      break;
    }
  }
  if (!replaced) {
    dates.push(newDate);
  }
  return dates;
}

export function getNextArticleDates({
  article,
  count,
  fromDate = null,
  usePostponedForSorting = false,
}: {
  article: Article;
  count: number;
  fromDate?: null | Date;
  usePostponedForSorting?: boolean;
}): ArticleDate[] {
  if (!fromDate) {
    fromDate = getArticleDatesStartingPoint(article);
  }

  let dates: ArticleDate[] = [];

  // get dates from config
  if (article.reviewOccurrenceConfiguration) {
    const generatedDates: ArticleDate[] = getNextDatesFromConfig(
      article.reviewOccurrenceConfiguration,
      count,
      fromDate,
    ).map((date) => ({
      date,
      isGenerated: true,
      isCancelled: false,
    }));
    dates.push(...generatedDates);
  }

  // get dates from exceptions
  for (const [exceptionKey, exceptionValue] of Object.entries(
    article.reviewOccurrenceExceptions ?? {},
  )) {
    const exceptionDate = new Date(exceptionKey);
    // postponed
    if (typeof exceptionValue === "string") {
      const newDate = setTimeFromConfig(
        new Date(exceptionValue),
        article.reviewOccurrenceConfiguration,
      );
      if (isBefore(newDate, fromDate)) {
        continue;
      }
      dates = pushOrReplaceDate(dates, {
        date: exceptionDate,
        postponedTo: newDate,
        isGenerated: false,
        isCancelled: false,
      });
    }
    if (isBefore(exceptionDate, fromDate)) {
      continue;
    }
    // added
    if (exceptionValue === true || exceptionValue === undefined) {
      dates = pushOrReplaceDate(dates, {
        date: exceptionDate,
        isGenerated: false,
        isCancelled: false,
      });
    }
    // cancelled
    if (exceptionValue === false) {
      dates = pushOrReplaceDate(dates, {
        date: exceptionDate,
        isGenerated: false,
        isCancelled: true,
      });
    }
  }

  // sorting
  dates = sortBy(dates, (d) =>
    usePostponedForSorting
      ? (d.postponedTo ?? d.date).getTime()
      : d.date.getTime(),
  );

  return dates;
}
