import Select from "react-select";

interface SelectStatusProps {
  statuses: string[];
  value: { value: string; label: string }[];
  onChange: (value: any) => void;
  placeholder?: string;
  className?: string;
  zIndex?: number;
}

export default function SelectStatus({
  statuses,
  value,
  onChange,
  placeholder = "Status",
  className = "w-60",
  zIndex = 10,
}: SelectStatusProps) {
  return (
    <Select
      className={`${className} z-${zIndex}`}
      placeholder={placeholder}
      options={statuses.map((status) => ({
        value: status,
        label: status,
      }))}
      onChange={onChange}
      value={value}
      isMulti
    />
  );
}