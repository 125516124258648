import type { TextareaHTMLAttributes } from "react";
import type { UseFormRegister } from "react-hook-form";
import { forwardRef } from "react";
import clsx from "clsx";

type Props = {
  className?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = forwardRef<
  HTMLTextAreaElement,
  Props & Partial<ReturnType<UseFormRegister<any>>>
>(({ className = "", ...restProps }, ref) => {
  return (
    <textarea
      className={clsx(
        "block border border-sn-gray rounded w-full py-2 px-3 h-40",
        {
          [className]: !!className,
        },
      )}
      {...restProps}
      ref={ref}
    />
  );
});

export default TextArea;
