import type { RecurrenceConfig } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import axiosClient from "@/config/axiosClient";
import { setHours } from "date-fns";
import { useMutation } from "react-query";

type MutationData = {
  articleId: number;
  reviewOccurrenceConfiguration: RecurrenceConfig;
  reviewOccurrenceExceptions: Record<string, boolean | string>;
  reviewDoneNow?: boolean;
  reviewComment?: string;
};

export async function postArticlesMutation(fields: MutationData) {
  const url = `${process.env.BACKOFFICE_API_BASE_URL}/v2/up/updateArticles/?forceForegroundExecution=true`;
  const postData = {
    articles: [
      {
        id: fields.articleId,
        fields: {
          reviewOccurrenceConfiguration: {
            ...fields.reviewOccurrenceConfiguration,
            startDate: fields.reviewOccurrenceConfiguration?.startDate
              ? setHours(
                  fields.reviewOccurrenceConfiguration.startDate,
                  Number(
                    fields.reviewOccurrenceConfiguration.desiredTime || "08",
                  ),
                ).toISOString()
              : undefined,
          },
          reviewOccurrenceExceptions: fields.reviewOccurrenceExceptions,
          reviewDoneNow: fields.reviewDoneNow,
          reviewComment: fields.reviewComment,
        },
      },
    ],
  };
  return axiosClient.post(url, postData);
}

export const useArticlesMutation = () => {
  return useMutation(postArticlesMutation);
};
