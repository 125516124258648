import { useState } from "react";
import toast from "react-hot-toast";
import { useArticlesMutation } from "@/hooks/api/useArticlesMutation";
import { useUpdateResponsibleMutation } from "@/hooks/api/useUpdateResponsibleMutation";
import { setTimeFromConfig } from "@/components/forms/RecurrenceForm/helpers/articleDates";
import type { RecurrenceConfig } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import type { Article } from "@/hooks/api/types";

interface UseActionExecutionsProps {
  articles: Article[];
  selectedIds: number[];
}

export default function useActionExecutions({
  articles,
  selectedIds,
}: UseActionExecutionsProps) {
  const articlesMutation = useArticlesMutation();
  const updateResponsibleMutation = useUpdateResponsibleMutation();
  const [changingResponsible, setChangingResponsible] = useState(false);
  const [selectedResponsible, setSelectedResponsible] = useState<{
    value: number;
    label: string;
  } | null>(null);

  // Execute the responsible person change
  const executeResponsibleChange = async (pageManagerId: number) => {
    // Create a loading toast that we'll update manually
    const toastId = toast.loading("Uppdaterar ansvarig...");
    setChangingResponsible(true);

    try {
      // Prepare the mutation data according to the required format
      const postData = {
        articles: selectedIds.map((id) => ({ id })),
        pageManagerId,
      };

      // Use the mutation hook to make the API call
      await updateResponsibleMutation.mutateAsync(postData);

      // All updates completed successfully
      toast.success("Ansvarig uppdaterad!", { id: toastId });
      // Reset the dropdown
      setSelectedResponsible(null);
    } catch (error) {
      // Handle any errors
      toast.error("Ett fel uppstod", { id: toastId });
      console.error("Error updating responsible person:", error);
    } finally {
      setChangingResponsible(false);
    }
  };

  // Execute adding a date
  const executeAddDate = async (date: Date) => {
    if (selectedIds.length === 0) return;

    // Get the selected articles
    const selectedArticles = articles.filter((article) =>
      selectedIds.includes(article.id)
    );

    // Create a loading toast that we'll update manually
    const toastId = toast.loading("Sparar datum...");

    try {
      // Process each article sequentially
      for (const article of selectedArticles) {
        // Set time from config if available
        const adjustedDate = setTimeFromConfig(
          date,
          article.reviewOccurrenceConfiguration
        );

        // Create the exceptions object with the new date
        const reviewOccurrenceExceptions = {
          ...(article.reviewOccurrenceExceptions || {}),
          [adjustedDate.toISOString()]: true,
        };

        // Prepare the mutation data
        const data = {
          articleId: article.id,
          reviewOccurrenceExceptions,
          reviewOccurrenceConfiguration:
            article.reviewOccurrenceConfiguration || {},
        };

        // Wait for each mutation to complete before proceeding to the next
        await new Promise((resolve, reject) => {
          articlesMutation.mutate(data, {
            onSuccess: resolve,
            onError: reject,
          });
        });
      }

      // All updates completed successfully
      toast.success("Datum sparat!", { id: toastId });
    } catch (error) {
      // Handle any errors
      toast.error("Ett fel uppstod", { id: toastId });
      console.error("Error saving dates:", error);
    }
  };

  // Execute setting recurrence interval
  const executeSetInterval = async (config: RecurrenceConfig) => {
    if (selectedIds.length === 0) return;

    // Get the selected articles
    const selectedArticles = articles.filter((article) =>
      selectedIds.includes(article.id)
    );

    // Create a loading toast that we'll update manually
    const toastId = toast.loading("Sparar granskningsintervall...");

    try {
      // Process each article sequentially
      for (const article of selectedArticles) {
        // Prepare the mutation data
        const data = {
          articleId: article.id,
          reviewOccurrenceExceptions: article.reviewOccurrenceExceptions || {},
          reviewOccurrenceConfiguration: config,
        };

        // Wait for each mutation to complete before proceeding to the next
        await new Promise((resolve, reject) => {
          articlesMutation.mutate(data, {
            onSuccess: resolve,
            onError: reject,
          });
        });
      }

      // All updates completed successfully
      toast.success("Granskningsintervall sparat!", { id: toastId });
    } catch (error) {
      // Handle any errors
      toast.error("Ett fel uppstod", { id: toastId });
      console.error("Error saving recurrence interval:", error);
    }
  };

  return {
    articlesMutation,
    changingResponsible,
    selectedResponsible,
    setSelectedResponsible,
    executeResponsibleChange,
    executeAddDate,
    executeSetInterval,
  };
}