// @ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import {BaseEndpointErrorResponse} from "../../../hooks/api/types";
import useChangeReviewerFormQuery from "../../../hooks/api/useChangeReviewerFormQuery";
import SNToast, {renderToast} from "../../SNToast";
import {getFriendlyErrorMessage} from "../../../utils/errorCodeMessages";
import BlurryLoading from "../../BlurryLoading";
import ChangeReviewerActionsBar from "./components/ChangeReviewerActionsBar";
import ChangeReviewerTable, {Article} from "./components/ChangeReviewerTable";
import {getStatusByReviewDates} from "./ChangeReviewerUtils";
import useGetAllReviewersQuery from "../../../hooks/api/useGetAllReviewersQuery";
import useChangeReviewerFormMutation from "../../../hooks/api/useChangeReviewerFormMutation";
import axiosClient from "../../../config/axiosClient";
import {API_URLS} from "../../../config/constants";
import ProgressBar from "../../ui/ProgressBar";
import Alert from "../../ui/Alert";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Input from "../../ui/Input";
import Icon from "../../ui/Icon";
import type {SortByColumn} from "./components/ChangeReviewerHeaderRow";

const ChangeReviewerForm = () => {
  const [articlesResponse, setArticlesResponse] = useState<BaseEndpointErrorResponse | null>(null);
  const [originalArticles, setOriginalArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [articleFailures, setArticleFailures] = useState([]);
  const [allReviewers, setAllReviewers] = useState([]);
  const [shouldShowActions, setShouldShowActions] = useState(false);
  const [backgroundResultId, setBackgroundResultId] = useState(null);
  const [backgroundProgressPercentage, setBackgroundProgressPercentage] = useState(0);
  const [filterSearchQuery, setFilterSearchQuery] = useState('');
  const [filterReviewers, setFilterReviewers] = useState([]);
  const [filterStatuses, setFilterStatuses] = useState([]);
  const [initialSortById, setInitialSortById] = useState('pendingReviewDate');
  const [initialSortByDirection, setInitialSortByDirection] = useState(false);
  const searchRef = useRef(null);

  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'k') {
      searchRef?.current?.focus();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  // Extract the list of actual reviewers (ie the ones with articles) from the list of articles, remove duplicates, and sort by name
  const reviewers = originalArticles.map(article => article.currentReviewer).filter((value, index, self) =>
    index === self.findIndex((article) => (
      article.id === value.id
    ))
  ).sort((a, b) => a.name.localeCompare(b.name));

  const columns = React.useMemo(
    () => [
      {
        Header: 'Titel',
        accessor: 'title',
      },
      {
        Header: 'Typ av innehåll',
        accessor: 'contentTypeLabel',
      },
      {
        Header: 'Hemsektion',
        accessor: 'homeSectionName',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Deadline',
        accessor: 'pendingReviewDate',
      },
      {
        Header: 'Faktagranskare',
        accessor: 'currentReviewerName',
      },
      {
        Header: '',
        accessor: 'url',
      },
    ],
    []
  );
  const getArticlesQuery = useChangeReviewerFormQuery();
  const getAllReviewersQuery = useGetAllReviewersQuery();
  const changeReviewerMutation = useChangeReviewerFormMutation();

  useEffect(() => {
    if (getArticlesQuery.status === 'error') {
      // @ts-ignore
      setArticlesResponse(getArticlesQuery?.error?.response?.data)
    }
    if (getArticlesQuery.status !== 'success') {
      return
    } else {
      let processedArticles = getArticlesQuery?.data?.articles;
      if (processedArticles?.length > 0) {
        processedArticles = processedArticles.map((art: Article) => ({ ...art, currentReviewerName: art.currentReviewer?.name }));
        setOriginalArticles(processedArticles);
      } else {
        setOriginalArticles([]);
        setFilteredArticles([]);
      }
    }
  }, [getArticlesQuery.status]);

  useEffect(() => {
    if (getArticlesQuery.status === 'error') {
      // @ts-ignore
      setAllReviewersResponse(getAllReviewersQuery?.error?.response?.data)
    }
    if (getAllReviewersQuery.status !== 'success') {
      return
    } else {
      setAllReviewers(getAllReviewersQuery?.data?.coworkers ?? []);
    }
  }, [getAllReviewersQuery.status]);

  useEffect(() => {
    if (backgroundResultId) {
      triggerBackgroundCheck();
    }
  }, [backgroundResultId]);

  useEffect(() => {
    applyFilters();
  }, [filterReviewers, filterSearchQuery, filterStatuses, originalArticles]);

  const applyFilters = () => {
    let newArticles;

    newArticles = filterBySearch(originalArticles);
    newArticles = filterByReviewers(newArticles);
    newArticles = filterByStatuses(newArticles);
    newArticles = newArticles.map((art: Article) => ({ ...art, currentReviewerName: art.currentReviewer?.name }));
    setFilteredArticles(newArticles);
  };

  const refresh = async () => {
    await getArticlesQuery.remove();
    await getArticlesQuery.refetch();
    applyFilters();
  };

  const filterBySearch = (arts: []): Article[] => {
    if (filterSearchQuery.length > 0) {
      return arts.filter(article => {
        const reviewDate = article?.pendingReviewDate;
        const ongoingReviewStartDate = article?.ongoingReviewStartDate;
        const status = getStatusByReviewDates(reviewDate, ongoingReviewStartDate);

        const foundByID = article.id.toString().includes(filterSearchQuery.toLowerCase());
        const foundByTitle = article.title.toLowerCase().includes(filterSearchQuery.toLowerCase());
        const foundByCurrentReviewer = article?.currentReviewer?.name.toLowerCase().includes(filterSearchQuery.toLowerCase());
        const foundByHomeSection = article?.homeSectionName?.toLowerCase().includes(filterSearchQuery.toLowerCase());
        const foundByStatus = status.toLowerCase().includes(filterSearchQuery.toLowerCase());
        const foundByContentType = article.contentTypeLabel.toLowerCase().includes(filterSearchQuery.toLowerCase());

        return foundByID || foundByTitle || foundByCurrentReviewer || foundByHomeSection || foundByStatus || foundByContentType;
      });
    } else {
      return arts;
    }
  };

  const filterByReviewers = (articles: Article[]) => {
    if (filterReviewers?.length > 0) {
      const reviewerIds = filterReviewers.map(r => r.value);
      return articles.filter((article: Article) => {
        return reviewerIds.includes(article?.currentReviewer?.id)
      });
    } else {
      return articles;
    }
  }

  const filterByStatuses = (articles: Article[]) => {
    if (filterStatuses?.length === 0) {
      return articles;
    }
    return articles.filter((article: Article) => {
      const reviewDate = article?.pendingReviewDate;
      const ongoingReviewStartDate = article?.ongoingReviewStartDate;
      const status = getStatusByReviewDates(reviewDate, ongoingReviewStartDate);
      return filterStatuses.map(item => item.value).includes(status);
    });
  };

  const onRowSelected = (rows: any) => {
    setShouldShowActions(rows?.length > 0);
    setSelectedArticles(rows);
  };

  const onSetReviewer = async (userId: string): void => {
    confirmAlert({
      title: 'Är du säker?',
      message: `Är du säker på att du vill uppdatera faktagranskare för ${selectedArticles?.length} ${selectedArticles?.length === 1 ? 'artikel' : 'artiklar'}?`,
      buttons: [
        {
          label: 'JA',
          onClick: () => {
            changeReviewerMutation.mutate({
              reviewerId: userId,
              articleIds: selectedArticles
            }, {
              onError: (err: any) => {
                renderToast({type: 'error', title: getFriendlyErrorMessage(err?.response?.data?.errorCode), err})
              },
              onSuccess: response => {
                setBackgroundResultId(response?.backgroundResultId);
                refresh();
              },
            });
          }
        },
        {
          label: 'NEJ',
        }
      ]
    });
  }

  const triggerBackgroundCheck = async () => {
    const inter = setInterval(() => {
      axiosClient
        .get(`${API_URLS.FORMS.CHANGE_REVIEWER.POST}/?backgroundResultId=${backgroundResultId}`)
        .then(res => {
          const percentageDone = res?.data?.backgroundRunnerPercentDone;
          const isRunning = res?.data?.backgroundRunnerIsRunning;
          const articleFailuresIds = res?.data?.articleFailures;
          const articleFailures = getArticlesByIdArray(articleFailuresIds);
          setBackgroundProgressPercentage(percentageDone);
          if (!isRunning) {
            clearInterval(inter);

            if (percentageDone === 100) {
              renderToast({
                type: 'success',
                title: 'Faktagranskare har nu ändrats för valda artiklar. Tänk på att det kan ta tid för ändringarna att synas i listan nedan. Vänta 20-30 sekunder och ladda om sidan för att se ändringarna.',
              });
              setTimeout(() => {
                setBackgroundResultId(null);
                setBackgroundProgressPercentage(0);
              }, 2000)
            } else {
              setArticleFailures(articleFailures);
            }
          }
        }).catch(() => {
          clearInterval(inter);
      })
    }, 1000);
  };

  const handleSortClicked = (sortBy: SortByColumn) => {
    setInitialSortById(sortBy.id);
    setInitialSortByDirection(sortBy.directionDesc);
  };

  const getArticlesByIdArray = (articleFailureIds: string[]): { id: string, title: string } => {
    return articleFailureIds.map(id => originalArticles.find(art => art.id === id));
  }

  return (
    <>
      <div className="w-full justify-center mx-auto">
        {(getArticlesQuery.status === 'loading' || changeReviewerMutation.isLoading) && <BlurryLoading/>}
        {articlesResponse && articlesResponse.errorCode.length > 0 && (
          <div className="flex justify-center">
            <SNToast type={'error'} title={getFriendlyErrorMessage(articlesResponse?.errorCode)}/>
          </div>
        )}
        <div className="flex justify-between items-center">
          <h1 className="whitespace-nowrap mr-8">Artiklar som skall faktagranskas</h1>
          <label htmlFor="search" className="w-full relative text-gray-400 focus-within:text-gray-600 block">
            <Icon className="pointer-events-none w-4 h-4 absolute top-6 transform -translate-y-1/2 left-3" icon="search" color={"#aaa"} />
            <Input
              ref={searchRef}
              id="search"
              className="mb-4 pl-8"
              placeholder="Sök på titel, innehållstyp, hemsektion, status..."
              onChange={(e) => setFilterSearchQuery(e?.target?.value)}
            />
          </label>
        </div>
        <ChangeReviewerActionsBar
          reviewers={reviewers}
          allReviewers={allReviewers}
          showActions={shouldShowActions}
          selectedArticles={selectedArticles}
          handleFilterByReviewers={setFilterReviewers}
          handleFilterByStatus={setFilterStatuses}
          handleSetReviewer={onSetReviewer}
        />
        {backgroundResultId && (
          <ProgressBar className="mb-4" percentage={backgroundProgressPercentage} />
        )}
        {articleFailures?.length > 0 && (
          <Alert type={'error'}>
            Faktagranskare kunde inte uppdateras på nedanstående artiklar. Försök igen om en stund eller kontakta <a className="text-white" href={`mailto:redaktionen@svensktnaringsliv.se`}>redaktionen@svensktnaringsliv.se</a>.
          </Alert>
        )}
        <ChangeReviewerTable
          columns={columns}
          data={filteredArticles}
          initialSortById={initialSortById}
          initialSortByIdDirection={initialSortByDirection}
          handleRowSelected={onRowSelected}
          handleRefreshClicked={refresh}
          handleSortClicked={handleSortClicked}
        />
      </div>
    </>
  )
}

export default ChangeReviewerForm;
