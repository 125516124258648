import ButtonOld from "../components/ui/ButtonOld";
import type { ReactNode } from 'react'
import usePostHawkCacheMutation from "../hooks/api/usePurgeHawkCacheQuery";
import { confirmAlert } from 'react-confirm-alert';

const ManageHawk = () => {
  const mutation = usePostHawkCacheMutation();

  const handleSubmit = (xkeys: string[], description: string) => {
    confirmAlert({
      title: 'Are you sure?',
      message: `About to clear the cache for: "${description}" (${xkeys}). Are you sure?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutation.mutate(xkeys);
          }
        },
        {
          label: 'No, cancel',
          onClick: () => {}
        },
      ]
    });
  };

  function HawkButton({xkeys, description, children = description }: {xkeys: string[], description: string, children?: ReactNode}) {
    console.info("children:", children, typeof children);
    console.info("xkeys:", xkeys);
    console.info("description:", description);
    return (
          <ButtonOld
            onClick={() => handleSubmit(xkeys, description)}
            size="l"
            className="mt-6 m-4"
            type="submit"
            disabled={mutation.isLoading}
          >
            {children}
          </ButtonOld>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h1>Clear the Cache in Hawk</h1>
      <p>Clear all content from the cache in Hawk, or selected content.</p>
      <div className="mt-6 border-t border-t-sn-gray">
        <HawkButton xkeys={['all-content']}    description='All data - All publications' >Everything</HawkButton>
      </div>
      <div className="mt-6 border-t border-t-sn-gray">
        <HawkButton xkeys={['sn-all-content']} description='All data - SN publication'   >SN - Everything</HawkButton> 
        <HawkButton xkeys={['ef-all-content']} description='All data - EF publication'   >EF - Everything</HawkButton>
      </div>
      <div className="mt-6 border-t border-t-sn-gray">
        <HawkButton xkeys={['sn-/']}           description='Start page - SN publication' >SN - Startpage</HawkButton>
        <HawkButton xkeys={['ef-/']}           description='Start page - EF publication' >EF - Startpage</HawkButton>
      </div>
      <div className="mt-6 border-t border-t-sn-gray">
        <HawkButton xkeys={['sn-/.menu/ ']}    description='Menu - SN publication'       >SN - Menu</HawkButton>
        <HawkButton xkeys={['ef-/.menu/ ']}    description='Menu - EF publication'       >EF - Menu</HawkButton>
      </div>
    </div>
  )
}

export default ManageHawk
