import { setTimeFromConfig } from "@/components/forms/RecurrenceForm/helpers/articleDates";
import type { RecurrenceConfig } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import {
  addDays,
  addWeeks,
  addMonths,
  setDay,
  setDate,
  getDay,
  getDate,
  lastDayOfMonth,
  isAfter,
  startOfWeek,
  addHours,
  eachDayOfInterval,
  endOfMonth,
  isBefore,
  isSameDay,
  startOfMonth,
  setHours,
  subDays,
  startOfDay,
  setMinutes,
} from "date-fns";

// Returns the very next occurrence AFTER the given date "last".
// If no occurrence is found in the current cycle, we jump to the next.
function getNextOccurrence(
  last: Date,
  options: RecurrenceConfig,
): Date | undefined {
  switch (options.repeatFrequency) {
    case "daily": {
      const interval = options.dailyInterval!;
      return addDays(last, interval);
    }

    case "weekly": {
      const sortedDays = options.weeklyDays!.map(Number).sort();
      const interval = options.weeklyInterval!;

      // check if we get sth this week
      const weekdaysLeftThisWeek = sortedDays.filter((d) => {
        if (getDay(last) === 0) {
          return false;
        }
        return d > getDay(last) || d === 0;
      });
      if (weekdaysLeftThisWeek.length > 0) {
        const firstWeekdayThatFits = weekdaysLeftThisWeek[0];
        return addDays(last, firstWeekdayThatFits - getDay(last));
      }

      // next week(s)
      const startOfNextWeek = startOfWeek(addWeeks(last, interval));
      const daysToAdd = sortedDays[0] === 0 ? 6 : sortedDays[0];
      return addDays(startOfNextWeek, daysToAdd);
    }

    case "monthly": {
      const sortedDays = options
        .selectedMonthDays!.map(Number)
        .sort((a, b) => a - b);
      const interval = options.monthlyInterval ?? 1;
      if (!interval) {
        return undefined;
      }
      if (options.monthlyRepeatMode === "byMonthDays") {
        // check if we get sth this month
        const selectedDaysLeftThisMonth = sortedDays.filter(
          (d) => d > getDate(last),
        );
        if (selectedDaysLeftThisMonth.length > 0) {
          const firstDayThatFits = selectedDaysLeftThisMonth[0];
          return setDate(last, firstDayThatFits);
        }

        // next month(s)
        const startOfNextMonth = addMonths(last, interval);
        const daysToAdd = sortedDays[0];
        return setDate(startOfNextMonth, daysToAdd);
      } else {
        const foundThisMonth = getMonthCandidate(
          addDays(last, 1),
          endOfMonth(last),
          options,
        );
        if (foundThisMonth) {
          return foundThisMonth;
        }
        // next month(s)
        const startOfNextMonth = startOfMonth(addMonths(last, interval));
        const endOfNextMonth = endOfMonth(startOfNextMonth);
        const foundNextMonth = getMonthCandidate(
          startOfNextMonth,
          endOfNextMonth,
          options,
        );
        if (foundNextMonth) {
          return foundNextMonth;
        }
      }
    }
  }
}

function getMonthCandidate(
  fromInclusive: Date,
  toInclusive: Date,
  options: RecurrenceConfig,
): Date | undefined {
  const daysThisMonth = eachDayOfInterval({
    start: startOfMonth(fromInclusive),
    end: endOfMonth(toInclusive),
  });
  for (const day of [...daysThisMonth]) {
    // past? skip
    if (isBefore(startOfDay(day), startOfDay(fromInclusive))) {
      continue;
    }
    // wrong weekday? skip
    if (
      options.monthlyWeekday !== "day" &&
      getDay(day) !== options.monthlyWeekday
    ) {
      continue;
    }
    // get nth/index of found day (example: 3 -> third sunday)
    const sameDaysThisMonth = [...daysThisMonth].filter(
      (d) =>
        options.monthlyWeekday === "day" ||
        getDay(d) === options.monthlyWeekday,
    );
    const dayNth = sameDaysThisMonth.indexOf(day);
    const occurrenceMap: Record<string, number> = {
      first: 1,
      second: 2,
      third: 3,
      fourth: 4,
      fifth: 5,
      last: -1,
    };
    const occurrence = occurrenceMap[options.monthlyOccurrence!];
    if (occurrence - 1 === dayNth) {
      return day;
    }
    if (occurrence === -1 && dayNth === sameDaysThisMonth.length - 1) {
      return day;
    }
  }
}

export function getNextDatesFromConfig(
  options: RecurrenceConfig,
  count: number,
  fromDate: null | Date = null,
): Date[] {
  const results: Date[] = [];
  if (!fromDate) {
    fromDate = setTimeFromConfig(new Date(), options);
  }
  let current = fromDate;

  for (let i = 0; i < count; i++) {
    let next = getNextOccurrence(current, options);
    if (next) {
      next = setTimeFromConfig(next, options);
      results.push(next);
      current = next;
    }
  }

  return results;
}
// For monthlyOccurrence
// 'first' => "1:e", 'second' => "2:e", 'third' => "3:e" etc.
// 'last' => "sista"
const occurrenceMap: Record<string, string> = {
  first: "1:e",
  second: "2:e",
  third: "3:e",
  fourth: "4:e",
  fifth: "5:e",
  last: "sista",
};
const weekdayNames = [
  "söndag",
  "måndag",
  "tisdag",
  "onsdag",
  "torsdag",
  "fredag",
  "lördag",
];
// We'll handle intervals in Swedish up to 5. (1=varje, 2=varannan, etc.) Beyond that we do var X:e.
// month= "månad", day= "dag", week= "vecka".
function swedishInterval(n: number, unit: "dag" | "vecka" | "månad"): string {
  if (n === 1) {
    return `varje ${unit}`;
  }
  if (n === 2) {
    // e.g. "varannan dag", "varannan vecka", "varannan månad"
    return `varannan ${unit}`;
  }
  if (n === 3) {
    // "var tredje"
    return `var tredje ${unit}`;
  }
  if (n === 4) {
    return `var fjärde ${unit}`;
  }
  if (n === 5) {
    return `var femte ${unit}`;
  }
  // fallback e.g. "var 6:e dag"
  return `var ${n}:e ${unit}`;
}

// Build a Swedish, user-friendly description of the repetition settings
export function describeRecurrenceOptions(options: RecurrenceConfig): string {
  switch (options.repeatFrequency) {
    case "daily": {
      const interval = options.dailyInterval ?? 1;
      // e.g. "Upprepas varje dag", "Upprepas var tredje dag"
      return `Upprepas ${swedishInterval(interval, "dag")}`;
    }

    case "weekly": {
      const interval = options.weeklyInterval ?? 1;
      const days = options.weeklyDays || [];
      // Map numeric days to Swedish names
      const dayNames = days.map((d) => weekdayNames[d]).sort();

      // Join days with comma, last with 'och'
      let joinedDays = "";
      if (dayNames.length > 1) {
        joinedDays =
          dayNames.slice(0, -1).join(", ") +
          " och " +
          dayNames[dayNames.length - 1];
      } else {
        joinedDays = dayNames[0] || "";
      }

      // e.g. "Upprepas varannan vecka, varje måndag och tisdag"
      // or "Upprepas var tredje vecka, varje onsdag"
      if (joinedDays) {
        return `Upprepas ${swedishInterval(interval, "vecka")}, varje ${joinedDays}`;
      } else {
        // If no days were specified for some reason
        return `Upprepas ${swedishInterval(interval, "vecka")}`;
      }
    }

    case "monthly": {
      const interval = options.monthlyInterval ?? 1;
      if (options.monthlyRepeatMode === "byMonthDays") {
        // e.g. "Upprepas varannan månad, dag 1,2"
        // or "Upprepas var tredje månad, dag 10"
        const daysList = options.selectedMonthDays
          ?.sort((a, b) => a - b)
          .join(", ");
        return `Upprepas ${swedishInterval(interval, "månad")}, dag ${daysList}`;
      } else {
        // monthlyAt
        // e.g. "Upprepas varannan månad, var 3:e onsdag"
        const occKey = options.monthlyOccurrence ?? "first";
        let occString = occurrenceMap[occKey]; // e.g. "3:e" if 'third'
        let wday = options.monthlyWeekday;
        let wdayString = wday === "day" ? "dag" : weekdayNames[wday as number];
        // If it's 'sista', we do "sista onsdagen" or "sista dagen"
        // If it's e.g. "3:e" we do "3:e onsdagen"
        // We'll handle that grammar:
        if (occString === "sista") {
          // "sista onsdagen"
          wdayString =
            wday === "day" ? "dagen" : weekdayNames[wday as number] + "en";
          // e.g. "sista onsdagen", "sista dagen"
          occString = "sista";
        } else {
          // e.g. "3:e onsdagen", but if wday= 'day', then "3:e dagen"
          if (wday === "day") {
            wdayString = "dagen";
          } else {
            wdayString = wdayString + "en"; // "onsdagen", "måndagen"
          }
        }

        const frontPart = `Upprepas ${occString} ${wdayString}`;
        // e.g. "Upprepas 3:e onsdagen"

        const backPart = `${swedishInterval(interval, "månad")}`;
        // e.g. "var tredje månad"

        // final string: "Upprepas 3:e onsdagen, var tredje månad"
        return `${frontPart}, ${backPart}`;
      }
    }

    default:
      return "Ingen repetition";
  }
}
