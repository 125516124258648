import PlannerItem from "@/components/forms/UpdatePlannerForm/PlannerItem";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import type { Article } from "../../../../hooks/api/types";

interface ArticleDialogProps {
  activeArticle: Article | null;
  setActiveArticle: (article: Article | null) => void;
}

export default function ArticleDialog({
  activeArticle,
  setActiveArticle,
}: ArticleDialogProps) {
  return (
    <Dialog
      open={!!activeArticle}
      onOpenChange={(open) => setActiveArticle(null)}
    >
      <DialogContent className="max-w-xl">
        <VisuallyHidden>
          <DialogHeader>
            <DialogTitle>{activeArticle?.title}</DialogTitle>
          </DialogHeader>
        </VisuallyHidden>
        <div className="grid gap-4 pb-4">
          {activeArticle && (
            <PlannerItem
              article={activeArticle}
              onClose={() => setActiveArticle(null)}
            />
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => setActiveArticle(null)}>
            Stäng
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}