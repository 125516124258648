import type { ArticleDate } from "@/components/forms/RecurrenceForm/helpers/articleDates";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import clsx from "clsx";
import { isPast, isToday, isValid } from "date-fns";
import { sv } from "date-fns/locale";
import {
  CalendarClockIcon,
  CalendarSyncIcon,
  CalendarXIcon,
  RefreshCwIcon,
  RefreshCwOffIcon,
} from "lucide-react";
import React from "react";
import { EllipsisVertical } from "lucide-react";

export default function FutureDates({
  nextDates,
  onDateRemove,
  onDateRestore,
  onDateChange,
}: {
  nextDates: ArticleDate[];
  onDateRemove: (date: Date) => void;
  onDateRestore: (date: Date) => void;
  onDateChange: (oldDate: Date, newDate: Date) => void;
}) {
  const [dropdownPopover, setDropdownPopover] = React.useState<Date | false>(
    false,
  );

  return (
    <>
      {nextDates.length === 0 && (
        <div className="p-2 bg-gray-200 text-gray-400 test-sm text-center my-1">
          Inga kommande datum...
        </div>
      )}
      {nextDates.length > 0 && (
        <Table className="styled-table">
          <TableBody>
            {nextDates.map((d) => {
              const actualDate = d.postponedTo ?? d.date;
              return (
                <TableRow
                  key={d.date.getTime()}
                  className={clsx({
                    danger: !isToday(actualDate) && isPast(actualDate),
                    warning: isToday(actualDate),
                  })}
                >
                  <TableCell className="">
                    <div className="flex items-center gap-2">
                      {d.isGenerated ? (
                        <span title="Det här datumet kommer från granskningsintervalet">
                          <RefreshCwIcon className="h-4 -ml-2" />
                        </span>
                      ) : (
                        <span title="Det här datumet lades till manuellt">
                          <RefreshCwOffIcon className="h-4 -ml-2" />
                        </span>
                      )}
                      {(d.isCancelled || d.postponedTo) && (
                        <>
                          <del>
                            {d.date.toLocaleDateString("sv-SE", {
                              dateStyle: "full",
                            })}
                          </del>
                          {d.postponedTo && (
                            <>
                              {" "}
                              &rarr;{" "}
                              {d.postponedTo.toLocaleDateString("sv-SE", {
                                dateStyle: "full",
                              })}
                            </>
                          )}
                        </>
                      )}
                      {!d.isCancelled && !d.postponedTo && (
                        <>
                          {d.date.toLocaleDateString("sv-SE", {
                            dateStyle: "full",
                          })}
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="py-1 text-right">
                    <div className="flex gap-2 justify-end">
                      <DropdownMenu
                        onOpenChange={() => setDropdownPopover(false)}
                        open={
                          dropdownPopover &&
                          dropdownPopover.getTime() === d.date.getTime()
                        }
                      >
                        <DropdownMenuTrigger asChild>
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => setDropdownPopover(d.date)}
                          >
                            <EllipsisVertical />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuGroup>
                            <DropdownMenuSub>
                              <DropdownMenuSubTrigger>
                                <CalendarClockIcon />
                                Ändra
                              </DropdownMenuSubTrigger>
                              <DropdownMenuSubContent>
                                <Calendar
                                  weekStartsOn={1}
                                  locale={sv}
                                  mode="single"
                                  selected={d.postponedTo ?? d.date}
                                  onSelect={(newDate: Date | undefined) => {
                                    if (!newDate) return;
                                    onDateChange(d.date, newDate);
                                    setDropdownPopover(false);
                                  }}
                                />
                              </DropdownMenuSubContent>
                            </DropdownMenuSub>
                            {!d.isCancelled && (
                              <DropdownMenuItem
                                onClick={() => {
                                  onDateRemove(d.date);
                                  setDropdownPopover(false);
                                }}
                              >
                                <CalendarXIcon />
                                Ställ in
                              </DropdownMenuItem>
                            )}
                            {d.isCancelled && (
                              <DropdownMenuItem
                                onClick={() => onDateRestore(d.date)}
                              >
                                <CalendarSyncIcon />
                                Återställ
                              </DropdownMenuItem>
                            )}
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
}
