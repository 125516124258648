import Select from "react-select";

interface SelectContentTypeProps {
  contentTypes: { value: string; label: string }[];
  value: { value: string; label: string }[];
  onChange: (value: any) => void;
  placeholder?: string;
  className?: string;
  zIndex?: number;
}

export default function SelectContentType({
  contentTypes,
  value,
  onChange,
  placeholder = "Innehållstyp",
  className = "w-60",
  zIndex = 10,
}: SelectContentTypeProps) {
  return (
    <Select
      className={`${className} z-${zIndex}`}
      placeholder={placeholder}
      options={contentTypes}
      onChange={onChange}
      value={value}
      isMulti
    />
  );
}