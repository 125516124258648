import type { Article } from "@/hooks/api/types";
import { getNextArticleDates } from "@/components/forms/RecurrenceForm/helpers/articleDates";
import { isBefore, isSameDay, startOfDay } from "date-fns";

export type StatusType =
  | "kommande"
  | "uppdaterad"
  | "uppdatera"
  | "uppdaterad_ej_publicerad"
  | "forsenad";

export interface StatusConfig {
  label: string;
}

export const statusConfigs: Record<StatusType, StatusConfig> = {
  kommande: {
    label: "Kommande",
  },
  uppdaterad: {
    label: "Uppdaterad",
  },
  uppdatera: {
    label: "Uppdatera",
  },
  uppdaterad_ej_publicerad: {
    label: "Uppdaterad, ej publicerad",
  },
  forsenad: {
    label: "Försenad",
  },
};

export function getArticleStatus(article: Article): StatusType {
  const now = new Date();
  const today = startOfDay(now);

  // Get the next update date
  const nextDate = getNextArticleDates({
    article,
    count: 1,
    usePostponedForSorting: true,
  })?.[0];

  if (!nextDate) {
    return "kommande"; // Default if no next date
  }

  const effectiveDate = nextDate.postponedTo ?? nextDate.date;
  const effectiveDay = startOfDay(effectiveDate);

  // Check if updated today
  if (article.lastReview?.lastReviewTime) {
    const reviewTime =
      typeof article.lastReview.lastReviewTime === "string"
        ? new Date(article.lastReview.lastReviewTime)
        : article.lastReview.lastReviewTime;

    if (isSameDay(reviewTime, now)) {
      // If updated today, check if published
      // Note: This is a placeholder as we don't have a clear way to determine if it's published
      // You may need to adjust this based on actual data available
      return "uppdaterad";
    }
  }

  // Check if it should be updated today
  if (isSameDay(effectiveDay, today)) {
    return "uppdatera";
  }

  // Check if delayed
  if (isBefore(effectiveDay, today)) {
    return "forsenad";
  }

  // Default: upcoming
  return "kommande";
}

export function getStatusLabel(status: StatusType): string {
  return statusConfigs[status].label;
}
