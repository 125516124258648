import { monthlyOccurrences } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import React from "react";
import type { ComponentProps } from "react";

export default function MonthlyOccurrencesSelect(
  props: ComponentProps<typeof Select>,
) {
  return (
    <>
      <Select {...props}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Vilken dag" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.entries(monthlyOccurrences).map(([key, value]) => (
              <SelectItem key={key} value={key}>
                {value}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
}
