import { useEffect } from "react";
import toast from "react-hot-toast";
import type { RecurrenceConfig } from "@/components/forms/RecurrenceForm/RecurrenceTypes";
import type { Article } from "@/hooks/api/types";
import ConfirmationModal from "./ConfirmationModal";
import useConfirmation from "../hooks/useConfirmation";
import useActionExecutions from "../hooks/useActionExecutions";
import ActionSheet from "./ActionSheet";

interface ActionControlsProps {
  articles: Article[];
  selectedIds: number[];
  people: { id: number; name: string }[];
}

export default function ActionControls({
  articles,
  selectedIds,
  people,
}: ActionControlsProps) {
  const {
    articlesMutation,
    changingResponsible,
    selectedResponsible,
    setSelectedResponsible,
    executeResponsibleChange,
    executeAddDate,
    executeSetInterval,
  } = useActionExecutions({ articles, selectedIds });

  // Confirmation hooks for different actions
  const responsibleConfirmation = useConfirmation<number>({
    skipConfirmationKey: "skipResponsibleConfirmation",
    onExecute: executeResponsibleChange,
  });

  const dateConfirmation = useConfirmation<Date>({
    skipConfirmationKey: "skipDateConfirmation",
    onExecute: executeAddDate,
  });

  const intervalConfirmation = useConfirmation<RecurrenceConfig>({
    skipConfirmationKey: "skipIntervalConfirmation",
    onExecute: executeSetInterval,
  });

  // Reset the responsible person dropdown when selection changes
  useEffect(() => {
    setSelectedResponsible(null);
  }, [selectedIds, setSelectedResponsible]);

  // Reset confirmation dialogs helper functions
  const resetDontAskAgainSetting = () => {
    responsibleConfirmation.resetDontAskAgainSetting();
    toast.success("Bekräftelsedialog för ansvarig återställd");
  };

  const resetDontAskAgainDateSetting = () => {
    dateConfirmation.resetDontAskAgainSetting();
    toast.success("Bekräftelsedialog för datum återställd");
  };

  const resetDontAskAgainIntervalSetting = () => {
    intervalConfirmation.resetDontAskAgainSetting();
    toast.success("Bekräftelsedialog för granskningsintervall återställd");
  };

  // Handle changing responsible person for multiple articles
  const handleChangeResponsible = (pageManagerId: number | undefined) => {
    if (!pageManagerId || selectedIds.length === 0) return;
    responsibleConfirmation.handleAction(pageManagerId);
  };

  // Handle adding a date with confirmation
  const handleAddDate = (date: Date) => {
    if (selectedIds.length === 0) return;
    dateConfirmation.handleAction(date);
  };

  // Handle setting recurrence interval with confirmation
  const handleSetInterval = (config: RecurrenceConfig) => {
    if (selectedIds.length === 0) return;
    intervalConfirmation.handleAction(config);
  };

  // Get the selected person's name for the confirmation dialog
  const getSelectedPersonName = () => {
    const responsibleId = responsibleConfirmation.pendingValue;
    if (!responsibleId) return "";
    const selectedPerson = people.find(
      (person) => person.id === responsibleId
    );
    return selectedPerson?.name || "";
  };

  return (
    <>
      {/* Actions button and sheet for all screen sizes */}
      <div>
        {selectedIds.length > 0 && (
          <ActionSheet
            selectedIds={selectedIds}
            articlesCount={articles.length}
            people={people}
            changingResponsible={changingResponsible}
            selectedResponsible={selectedResponsible}
            setSelectedResponsible={setSelectedResponsible}
            handleChangeResponsible={handleChangeResponsible}
            handleAddDate={handleAddDate}
            handleSetInterval={handleSetInterval}
            responsibleConfirmation={responsibleConfirmation}
            dateConfirmation={dateConfirmation}
            intervalConfirmation={intervalConfirmation}
            resetDontAskAgainSetting={resetDontAskAgainSetting}
            resetDontAskAgainDateSetting={resetDontAskAgainDateSetting}
            resetDontAskAgainIntervalSetting={resetDontAskAgainIntervalSetting}
            savingStatus={articlesMutation.status}
          />
        )}
      </div>

      {/* Responsible Person Confirmation Dialog */}
      <ConfirmationModal
        open={responsibleConfirmation.showConfirmDialog}
        onOpenChange={responsibleConfirmation.setShowConfirmDialog}
        description={`Är du säker på att du vill uppdatera ansvarig till ${getSelectedPersonName()} för ${selectedIds.length} ${
          selectedIds.length === 1 ? "artikel" : "artiklar"
        }?`}
        onConfirm={responsibleConfirmation.handleConfirm}
        onCancel={responsibleConfirmation.handleCancel}
        skipConfirmationKey="skipResponsibleConfirmation"
      />

      {/* Add Date Confirmation Dialog */}
      <ConfirmationModal
        open={dateConfirmation.showConfirmDialog}
        onOpenChange={dateConfirmation.setShowConfirmDialog}
        description={`Är du säker på att du vill lägga till datum för ${
          selectedIds.length
        } ${selectedIds.length === 1 ? "artikel" : "artiklar"}?`}
        onConfirm={dateConfirmation.handleConfirm}
        onCancel={dateConfirmation.handleCancel}
        skipConfirmationKey="skipDateConfirmation"
      />

      {/* Set Interval Confirmation Dialog */}
      <ConfirmationModal
        open={intervalConfirmation.showConfirmDialog}
        onOpenChange={intervalConfirmation.setShowConfirmDialog}
        description={`Är du säker på att du vill ställa granskningsintervall för ${
          selectedIds.length
        } ${selectedIds.length === 1 ? "artikel" : "artiklar"}?`}
        onConfirm={intervalConfirmation.handleConfirm}
        onCancel={intervalConfirmation.handleCancel}
        skipConfirmationKey="skipIntervalConfirmation"
      />
    </>
  );
}
