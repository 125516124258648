import axiosClient from "@/config/axiosClient";
import { useMutation } from "react-query";

type UpdateResponsibleData = {
  articles: { id: number }[];
  pageManagerId: number;
};

export async function postUpdateResponsible(data: UpdateResponsibleData) {
  const url = `${process.env.BACKOFFICE_API_BASE_URL}/v2/up/updateArticles/?forceForegroundExecution=true`;
  return axiosClient.post(url, data);
}

export const useUpdateResponsibleMutation = () => {
  return useMutation(postUpdateResponsible);
};